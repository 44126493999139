import React from 'react'
import { Page, Input, FormCommand, Title, Alert, Modal } from '../../components' 
import { withCamim, utils } from '../../utils'
import AppointmentService from '../../services/AppointmentService'
import LocationService from '../../services/LocationService'

class CancelAppointment extends React.Component {
    state = { 
        isRequesting: false, 
        showCancelModal: false,
        cancelling: false
    }

    constructor(props) {
        super(props)
        props.setForm('appt', {})
    }

    get appointmentService() {
        if (!this._appointmentService) {
            this._appointmentService = new AppointmentService(this.props)
        }
        return this._appointmentService
    }

    get appointment() {
        return this.state.appointment
    }

    canCancel = () => {

        const { isRequesting, appointment } = this.state

        if (isRequesting) {
            return this.props.isMobile ? 'Cancelando...' : 'Cancelando agendamento...'
        }

        const { cpf = '' } = this.props.getForm('appt')
        if (utils.isEmpty(cpf) || cpf.trim().replace(/[^0-9]/g, '').length !== 3) {
            return 'Informe os três dígitos do CPF'
        }
        else if (cpf !== (appointment.cpf || '').substr(0, 3)) {
            return 'Iniciais do CPF incorretas'
        }

        return null
    }

    getMyLocation = async () => {
        const location = window.navigator && window.navigator.geolocation
        
        if (!!location) {
          location.getCurrentPosition(
            ({ coords: coordinate }) => this.setState({ coordinate }),
            error => {}
          )
        }

        try {
            const ipInfo = await (new LocationService()).ipInfo()
            this.setState({ ipInfo })
        } catch (error) {
            
        }
    }

    askCancelAppointment = evt => {
        evt.preventDefault()
        this.setState({ showCancelModal: true })
    }

    cancelAppointment = async () => {
        try {
            this.setState({ cancelling: true, errorCancel: null })
            const { email, code } = this.appointment
            const filial = this.props.filials.find(e => e.code === this.appointment.filialCode)
            await this.appointmentService.cancelByCode(filial, code, email)
            this.setState({ showCancelModal: false, cancelling: false, wasCancelled: true })
        } catch (error) {
            this.setState({ cancelling: false, errorCancel: error.error || 'Não foi possível realizar o cancelamento. Entre em contato com nossa central de atendimento.' })
        }
    }

    componentDidMount = async () => {
        try {
            const { match: { params: { hash }}} = this.props
            const appointment = await this.appointmentService.getAppointmentByHash(hash)
            this.setState({ appointment })
            this.getMyLocation()
        } catch (error) {
            console.log(error)
            this.setState({ error: error.message || error.error })
            setTimeout(() => this.props.history.replace('/'), 3000)
        }
    }
    
    render = () => {
        const { appointment, isRequesting = false, error, showCancelModal, cancelling, wasCancelled, errorCancel } = this.state
        const { isMobile } = this.props
        const canCancel = this.canCancel()

        return (
            <Page 
                card 
                title='Cancelar Agendamento'
                hideBreadcrumb
            >
                <Modal 
                    isVisible={showCancelModal}
                    title='Cancelar o agendamento?'
                    yesTitle={cancelling ? 'Cancelando...' : errorCancel ? 'Tentar cancelar novamente' : 'Sim, cancelar'}
                    requesting={cancelling}
                    yesButtonColor='secondary'
                    onConfirm={this.cancelAppointment}
                    onCancel={() => this.setState({ showCancelModal: false })}
                >
                    <div>Confirmar o cancelamento deste agendamento?</div>
                    {errorCancel && <Alert danger>
                        {errorCancel}
                    </Alert>}
                </Modal>

                <Title success underline>Cancelar Agendamento</Title>
                {(!appointment || error) && (
                    <Alert 
                        ghost={!error}
                        danger={!!error}
                        requesting={!error}
                    >{error || 'Obtendo dados do agendamento...'}</Alert>
                )}

                {appointment && !wasCancelled && (
                    <>
                        <form autoComplete='off' className='formCancel' onSubmit={this.askCancelAppointment}>

                            <p>Olá <strong>{ appointment.name }!</strong></p>

                            <p>Você solicitou o cancelamento da consulta com o(a) Dr(ª)</p>

                            <div className="bolder">{ appointment.doctor }, { appointment.specialty }.</div>
                            <div>no dia <strong>{ appointment.date }</strong>, com horário previsto para o atendimento: <strong>{ appointment.time }h</strong>.</div>
                            <br />

                            <p className="smaller">
                                Para confirmar o cancelamento digite abaixo os <strong>três primeiros dígitos de seu CPF</strong> e clique no botão CANCELAR:
                            </p>

                            <br />
                            
                            <Input 
                                title="Três dígitos do CPF" 
                                placeholder="Três dígitos do CPF" 
                                type="password" 
                                maxLength="3" 
                                field="appt.cpf" 
                                onFormatValue={utils.limitPasswordChars(3)}
                            />

                            <FormCommand
                                isRequesting={isRequesting}
                                actionTitle={canCancel || (isMobile ? 'Cancelar' : 'Cancelar o Agendamento')}
                                actionButtonColor='secondary'
                                disabled={!!canCancel}
                            />
                        </form>
                    </>
                )}

                {wasCancelled && (
                    <Alert success>
                        <i className="fa fa-exclamation-circle"></i> <small>Agendamento cancelado com sucesso</small>
                    </Alert>
                )}
            </Page>
        )
    }
}

export default withCamim(CancelAppointment)

    
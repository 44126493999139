import React from 'react'
import { Grid } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import { Chip, Avatar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import '../../assets/styles/css/content.css'
import { StoreConnectRedux } from '../../store/ducks/StoreDuck'

const MainContent = ({ 
    title, 
    customBreadcrumb = {
        icon: <HomeIcon />,
        title: null
    }, 
    mustLogin,
    breadcrumb, 
    breads = [], 
    isMobile,
    isRegularScreen,
    children 
}) => {

    const sg = isRegularScreen ? 1 : 2
    const lg = isMobile ? 12 : isRegularScreen ? 10 : 8
    const fontStyle = isMobile && title && title.length > 8 ? { fontSize: 18 } : {}

    const mainTitle = customBreadcrumb.title || (isMobile ? 'Painel' : 'Área Administrativa')
    
    return (
        <React.Fragment>
            {!!title && (
                <div className={`fusion-page-title-bar fusion-page-title-bar-breadcrumbs fusion-page-title-bar-center ${!!breadcrumb ? 'halfSpaceAfterTitle' : 'spaceAfterTitle'}`}>
                    <div className="fusion-page-title-row">
                        <div className="fusion-page-title-wrapper">
                            <div className="fusion-page-title-captions">
                                <h1 className="entry-title" style={ fontStyle }>{title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <main className="contentContainer">
                <Grid container>
                    {!isMobile && <Grid item xs={sg} />}
                    <Grid item xs={lg}>
                    
                        {!!breadcrumb && (
                            <div className="halfSpaceAfterTitle">
                                {!mustLogin && <Link to='/'><Chip clickable avatar={<Avatar>{customBreadcrumb.icon}</Avatar>} label={mainTitle} /></Link>}
                                {breads.map((b, index) => (
                                    <React.Fragment key={index}>
                                    {(index === 0) && !mustLogin && <>&nbsp;/&nbsp;</>}
                                    {!!b.link && <Link to={b.link}><Chip clickable label={b.title} /></Link>}
                                    {!!b.href && <a href={b.href}><Chip clickable label={b.title} /></a>}
                                    </React.Fragment>
                                ))}
                                {(breads.length > 1 || !mustLogin) && (
                                    <>
                                        &nbsp;/
                                    </>
                                )} <Chip color='primary' label={breadcrumb} style={{ backgroundColor: '#6398A8' }} />
                            </div>
                        )}
                        {children}
                    </Grid>
                </Grid>
            </main>
        </React.Fragment>
    )
}

export default StoreConnectRedux()(MainContent)

import React from 'react'
import Login from '../modules/Login/Login'
import { StoreFormConnectRedux } from '../store/ducks/StoreDuck'
import { compose } from 'redux'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import ForceChangePassword from '../modules/ForceChangePassword/ForceChangePassword'
import camimFirebase from '../services/CamimFirebase'
import LoginExam from '../modules/Exam/LoginExam'

const AuthHOC = rule => WrapComponent => props => {

    if (props.dictionaryToTranslate.mustDownload && !props.dictionaryToTranslate.downloading) {
        props.setTranslate({ mustDownload: true, downloading: true })
        camimFirebase.once('config/settings').subscribe(props.setTranslate)
    }

    if (rule.mustDownloadSpecialties && (!props.specialties || props.specialties.length === [])) {
        !props.isLoadingFilials && props.loadingFilialsAndSpecialties()
        return (
            <CircularProgress
                size={20}
                color='primary'
                style={{ marginLeft: 16, marginTop: 16, color: '#007700' }}
            />
        )
    }
    else if (!props.filials || props.filials.length === []) {
        !props.isLoadingFilials && props.loadingFilials()
        return (
            <CircularProgress
                size={20}
                color='primary'
                style={{ marginLeft: 16, marginTop: 16, color: '#007700' }}
            />
        )
    }

    if (!!rule.onAuth) {
        const forbiddenAuth = rule.onAuth(props)
        if (!!forbiddenAuth) {
            return forbiddenAuth
        }
    }
    else if (!rule.guest) {
        const { mustLogin, logout, lastLogin, user } = props
        if (!!lastLogin && moment(lastLogin).add(30, 'm').isBefore(moment())) {
            logout()
        }
        else if (mustLogin) {
            return <Login {...props} />
        }
        else if (user.cryptBirthday === user.crypt || user.cryptCPF === user.crypt) {
            return <ForceChangePassword />
        }
    }
    
    return (
        <WrapComponent {...props} />
    )
}

export const withCamimSpecialties = compose(
    StoreFormConnectRedux(),
    AuthHOC({ guest: true, mustDownloadSpecialties: true }),
)

export const withCamim = compose(
    StoreFormConnectRedux(),
    AuthHOC({ guest: true }),
)

export const withAuthExam = compose(
    StoreFormConnectRedux(),
    AuthHOC({ onAuth: ({ mustLogin, mustLoginExam, ...rest }) => {
        if (!mustLogin) {
            return null
        }
        
        return mustLoginExam && <LoginExam {...rest} />
    }}),
)

export const withPortabilidade = compose(
    StoreFormConnectRedux(),
    AuthHOC({ onAuth: ({ mustLogin, ...rest }) => {
        if (!mustLogin) {
            return null
        }
        
        return <Login {...rest} hasPortabilidade />
    }}),
)

export const withAuth = compose(
    StoreFormConnectRedux(),
    AuthHOC({ onlyTitular: false }),
)

export const withAuthTitular = compose(
    StoreFormConnectRedux(),
    AuthHOC({ onlyTitular: true }),
)


import { compose, createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

// Reducers
import { StoreReducer } from './ducks/StoreDuck'
import { MemoryReducer, MemoryMiddleware } from './ducks/MemoryDuck'
import { FormReducer } from './ducks/FormDuck'
import Config from '../constants/Config'

const persistConfig = {
    key: 'camim',
    storage,
    stateReconciler: hardSet,
    blacklist: ['form', 'memory'],
}

const reducers = combineReducers({
    store: StoreReducer,
    memory: MemoryReducer,
    form: FormReducer,
})
const persistedReducer = persistReducer(persistConfig, reducers)

let midls = applyMiddleware(MemoryMiddleware)
if (!Config.isProduction) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    midls = compose(midls, composeEnhancers())
}

export const store = createStore(persistedReducer, midls)
export const persistor = persistStore(store)

export default {
    store,
    persistor,
}

import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export const Copy = styled(CopyToClipboard)`
    color: #007700;
    cursor: pointer;
    display: block;
    margin: 16px 0;
    text-decoration: underline;
`

import React from 'react'
import { SocialIcon as RNSocialIcon } from 'react-social-icons'

const SocialIcon = props => (
    <RNSocialIcon className="iconSocialNetwork" fgColor="#fff" bgColor="#157A34" {...props} />
)

export default () => (
    <>
        <section id="social_links-widget-3" className="fusion-footer-widget-column widget social_links">
            <h4 className="widget-title">Redes Sociais</h4>
            <div className="fusion-social-networks boxed-icons">
                <div className="fusion-social-networks-wrapper">
                    <SocialIcon url="https://www.facebook.com/grupocamim" />
                    <SocialIcon url="https://www.youtube.com/channel/UCophxv3heBpQshsKrh7ddMQ" />
                    <SocialIcon url="https://www.instagram.com/grupocamim/" />
                    <SocialIcon network="whatsapp" url="http://bit.ly/WhatsAppCamim" />
                </div>
            </div>
            <div className="headerCSS_11" />
        </section>
        <section id="media_image-2" className="fusion-footer-widget-column widget widget_media_image">
            <a href="http://www.ans.gov.br/">
                <img
                    width="185"
                    height="144"
                    src="https://www.camim.com.br/wp-content/uploads/2019/04/ans-badge.png"
                    className="image wp-image-1679  attachment-full size-full headerCSS_12"
                    alt=""
                />
            </a>
            <div className="headerCSS_13" />
        </section>
        <section id="custom_html-2" className="widget_text fusion-footer-widget-column widget widget_custom_html">
            <div className="textwidget custom-html-widget">
                <div className="headerCSS_14">
                    <img src="https://s3-sa-east-1.amazonaws.com/camim-site/wp-content/uploads/2019/06/05180920/ans.jpeg" alt="imagem" />
                </div>
            </div>
            <div className="headerCSS_15" />
        </section>
    </>
)

import React from 'react'
import { NavLink } from 'react-router-dom'
import { StoreConnectRedux } from '../../store/ducks/StoreDuck'
import { logoutObservable } from '../Page/Page'

class Header extends React.PureComponent {
    state = {}

    toggleChat = () => {}

    exitApp = () => this.setState({ isDialogOpened: true })

    dismiss = isLogout => {
        this.setState({ isDialogOpened: false })
        isLogout && this.props.logout()
    }

    render = () => {
        const { fullName, mustLogin } = this.props
        return (
            <header className="fusion-header-wrapper">
                <div className="fusion-header-v2 fusion-logo-left fusion-sticky-menu- fusion-sticky-logo- fusion-mobile-logo-1  fusion-mobile-menu-design-modern">
                    <div className="fusion-secondary-header">
                        <div className="fusion-row">
                            <div className="fusion-alignleft">
                                <nav className="fusion-secondary-menu" role="navigation" aria-label="Secondary Menu">
                                    <ul id="menu-menu-topo" className="menu">
                                        <li id="menu-item-1388" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1388" data-item-id="1388">
                                            <NavLink to="/mudanca-rede" className="fusion-background-highlight">
                                                <span className="menu-text">Mudança de Rede</span>
                                            </NavLink>
                                        </li>
                                        {/* <li id="menu-item-1763" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1763" data-item-id="1763">
                                            <NavLink to="/portabilidade" className="fusion-background-highlight">
                                                <span className="menu-text">Portabilidade Especial</span>
                                            </NavLink>
                                        </li> */}
                                        <li id="menu-item-1389" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1389" data-item-id="1389">
                                            {mustLogin && <NavLink to='/' className="fusion-background-highlight">
                                                <span className="menu-text">Área restrita para associados</span>
                                            </NavLink>}
                                            {!mustLogin && (
                                                <div className="userArea">
                                                    <span className="menu-text">Cliente: {fullName}</span>
                                                    &nbsp;(
                                                    <NavLink to='/' className="userAreaLink">
                                                        painel
                                                    </NavLink>
                                                    &nbsp;|&nbsp;
                                                    <button className="userAreaLinkButton" onClick={() => logoutObservable.onNext(true)}>
                                                        sair
                                                    </button>
                                                    )
                                                 </div>
                                            )}
                                        </li>
                                        <li id="menu-item-1401" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1401" data-item-id="1401">
                                            <a href="tel:2124559600" className="fusion-background-highlight">
                                                <span className="menu-text">Central de Marcação: (21) 2455-9600</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <nav className="fusion-mobile-nav-holder fusion-mobile-menu-text-align-left" aria-label="Secondary Mobile Menu" />
                            </div>
                        </div>
                    </div>
                    <div className="fusion-header-sticky-height" />
                    <div className="fusion-header">
                        <div className="fusion-row">
                            <div className="fusion-logo" data-margin-top="15px" data-margin-bottom="15px" data-margin-left="0px" data-margin-right="0px">
                                <a className="fusion-logo-link" href="https://www.camim.com.br/">
                                    <img
                                        src="https://s3-sa-east-1.amazonaws.com/camim-site/wp-content/uploads/2019/04/22115326/logo-camim.png"
                                        width=""
                                        height=""
                                        alt="Camim Logo"
                                        data-retina_logo_url=""
                                        className="fusion-standard-logo"
                                    />
                                    <img
                                        src="https://s3-sa-east-1.amazonaws.com/camim-site/wp-content/uploads/2019/04/22115327/logo-mobile.png"
                                        width=""
                                        height=""
                                        alt="Camim Logo"
                                        data-retina_logo_url=""
                                        className="fusion-mobile-logo"
                                    />
                                </a>
                            </div>
                            <nav className="fusion-main-menu" aria-label="Main Menu">
                                <ul id="menu-menu-principal" className="fusion-menu">
                                    <li id="menu-item-1346" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1346" data-item-id="1346">
                                        <a href="https://www.camim.com.br/a-empresa/" className="fusion-background-highlight">
                                            <span className="menu-text">A Camim</span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1541" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1541" data-item-id="1541">
                                        <a href="https://www.camim.com.br/especialidades/" className="fusion-background-highlight">
                                            <span className="menu-text">Especialidades</span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1661" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1661" data-item-id="1661">
                                        <a href="https://www.camim.com.br/postos/" className="fusion-background-highlight">
                                            <span className="menu-text">
                                                Postos de
                                                <br />
                                                atendimento
                                            </span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1306" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1306" data-item-id="1306">
                                        <a href="https://www.camim.com.br/blog/" className="fusion-background-highlight">
                                            <span className="menu-text">Blog</span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1629" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1629" data-item-id="1629">
                                        <a href="https://www.camim.com.br/trabalhe-conosco/" className="fusion-background-highlight">
                                            <span className="menu-text">Trabalhe Conosco</span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1368" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1368" data-item-id="1368">
                                        <a href="https://www.camim.com.br/contato/" className="fusion-background-highlight">
                                            <span className="menu-text">Contato</span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1472" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1472" data-item-id="1472">
                                        <a href="http://clubecamim.com.br/" className="fusion-background-highlight">
                                            <span className="menu-text">Clube Camim</span>
                                        </a>
                                    </li>
                                    <li id="menu-item-1929" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1929 fusion-menu-item-button" data-item-id="1929">
                                        <a href="https://www.camim.com.br/quero-me-associar/" className="fusion-background-highlight">
                                            <span className="menu-text fusion-button button-default button-medium">Quero me associar</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            {/* <div className="fusion-mobile-menu-icons">
                                <a href="https://www.google.com" className="fusion-icon fusion-icon-bars" aria-label="Toggle mobile menu" aria-expanded="false" />
                            </div> */}
                            <nav className="fusion-mobile-nav-holder fusion-mobile-menu-text-align-left" aria-label="Main Menu Mobile" />
                        </div>
                    </div>
                </div>
                <div className="fusion-clearfix" />
            </header>
        )
    }
}

export default StoreConnectRedux()(Header)

import FirebaseService from './FirebaseService'
import { api } from './ApiService'
import { AppointmentModel, AppointmentFilial } from '../models/AppointmentAnswer'
import { Observable } from 'rx'
import { Observable as Obs, merge } from 'rxjs'

export default class AppointmentService extends FirebaseService {
    userData = null
    dispatch = null
    currentIP = null
    extraData = {}

    constructor(props) {
        super(props)
        this.userData = !!props.user && props.user.userData
    }

    specialties = () => api.get('specialty?includesOnlinePsychology=true').then(specialties => {

        const spcs = specialties.map(e => ({ 
            title: e.especialidade, 
            value: e.keyEspecialidade, 
            hash: e.keyEspecialidade.replace(' ', '_'), 
            canSchedule: e.canSchedule === 1,
            isOnline: e.isOnline === 1,
            price: e.price || 0
        }))

        this.props.setSpecialties(spcs)
        
        return spcs
    })

    attendanceTime = hash => api.get(`${hash}/attendance-time`)

    setManualTimeout = () => { 
        this.isAutomaticallyManagerTimeout = false
        return this
    }

    checkAppointmentAndGetAvailableDates = (filials, specialty, period) => {
        const dictionary = {
            startDate: period.sqlStartDate,
            endDate: period.sqlEndDate,
            specialty,
            codeSource: this.props.user.codeFilial
        }

        const sortItems = filials.sort((bf, af) => bf.code < af.code ? -1 : 1)
        const [ last ] = sortItems.reverse()

        const observables = [...sortItems, last].map(filial => Obs.create(async emitter => {
            try {
                const result = await this
                    .firebase
                    .setNewTimeout(10)
                    .request('attendanceCheckAndAvailableDates', filial, dictionary)
                    
                emitter.next({
                    codeFilial: filial.code,
                    appointments: this.getAppointments(result)
                })
            } catch (error) {
                if (!filial) {
                    console.log('&&& error', error)
                }
                else {
                    emitter.next({ 
                        codeFilial: filial.code,
                        appointments: []
                    })
                }
            }
        }))

        return merge(...observables)
    }

    asyncCheckAppointmentAndGetAvailableDates = async ({ specialty, period, isPsychologyOnline = false }, callback) => {
        const data = {
            startDate: period.sqlStartDate,
            endDate: period.sqlEndDate,
            specialty,
            codeSource: this.props.user.codeFilial,
            isPsychologyOnline
        }

        let results = []

        for (const filial of this.filials) {
            try {
                const result = await this
                    .firebase
                    .setNewTimeout(10)
                    .request('attendanceCheckAndAvailableDates', filial, data)
                    
                const appointments = this.getAppointments(result)
                if (appointments.length > 0) {
                    callback && callback(appointments)
                    results = [...results, ...appointments]
                }
            } catch (error) {
                
            }
        }

        return results
    }

    getAppointments = result => {
        const currentFilial = this.filials.find(x => x.code === result.data.filialCode)
        return result.success ? result.items.map(e => new AppointmentModel(currentFilial.name, e)) : []
    }

    appointments = callback => {
        const filials = this.filials
        this.nextFilial(null, callback)
        return Observable.concat(...filials.map(e => this.firebase.requestObservable('appoiments', e, { isApp: true, nome: this.user.name, ...this.extraData }, true))).map(result => {
            this.nextFilial(result.data.filialCode, callback)
            return result.items
        })
    }

    /**
     * Criar agendamento
     */
    
    customerFromSourceFilial = filial => {
        const map = { idCliente: this.user.idCliente, idDependente: this.user.idDependente }
        return this.firebase.request('getCliente', filial, map).then(response => {
            if (!response.result) {
                return Promise.reject({ success: false, error: 'Erro na requisição'})
            }
            return response.result
        })
    }

    resolvePromise = async promise => {
        const result = await promise
        if (!result.success) {
            return Promise.reject({ success: false, error: 'Erro no Agendamento. Tente novamente'})
        }
        return result
    }

    updateWhatsapp = (filial, whatsappPhone) => this.resolvePromise(this.firebase.request('updateWhatsapp', filial, { whatsappPhone }))

    makeAppointment = async (filial, doctor, time, extra = {}) => {
        const result = await this.makeAppointmentGeneric(filial, doctor, time, extra)
        const item = new AppointmentFilial()
        item.parse(result.data.result.attendance)
        return item
    }

    makeAppointmentGeneric = async (filial, doctor, time, extra = {}) => {
        const user = this.user
        const map = {
            idCliente: user.idCliente,
            idDependente: user.idDependente,
            codeSource: user.codeFilial,
            doctorId: doctor.doctorId,
            specialtyId: doctor.specialtyId,
            date: doctor.sqlDate,
            time: time.time,
            number: time.number,
            isSite: false,
            extra
        }

        if (user.codeFilial !== doctor.filialCode) {
            if (!this.userData) {
                this.userData = await this.customerFromSourceFilial(user.filial)
                this.props.updateUser({...this.userData})
            }
            map.patient = {...this.userData}
        }

        if (user.idDependente !== 0 && user.dependente) {
            map.dependente = {
                idDependente: user.idDependente,
                idCliente: user.idCliente,
                ...user.dependente
            }
        }

        const result = await this.firebase.request('makeAppoimentTest', filial, map)
        if (!result.success) {
            return Promise.reject({ success: false, error: 'Erro no Agendamento. Tente novamente'})
        }

        return result
    }

    /** 
     * Cancelar
     */
    
    cancel = (filial, appointment, identification) => {
        const dictionary = {
            code: appointment.code,
            email: this.user.email,
        }

        if (!!identification) {
            // LatLng
            dictionary.lat = identification.latitude
            dictionary.lng = identification.longitude

            // IP
            if (!!identification.ip) {
                dictionary.ip = identification.ip
            }

        }

        return this.firebase.request('cancelAppoiment', filial, dictionary)
    }

    // confirm = (filial, appointment) => this.request('confirmAppointment', filial, { idLancamento: appointment.lancamentoId })

    getAppointmentByHash = hash =>
        new Promise(async (result, reject) => {
            try {
                // E12L3333C4444

                const [idEndereco, idLancamento, idCliente] = hash
                    .replace(/[^0-9]/g, ' ')
                    .trim()
                    .split(' ')

                if (!idEndereco || !idLancamento || !idCliente) {
                    reject({ message: 'Hash inválido' })
                }

                const filial = this.filials.find(e => e.idEndereco === parseInt(idEndereco || 0, 10))

                if (!filial) {
                    reject({ message: 'Posto do hash inexistente' })
                }

                const json = {
                    idLancamento: parseInt(idLancamento, 10),
                    idCliente: parseInt(idCliente, 10),
                }

                this.isForceRequest = true

                const rs = await this.firebase.request('attendanceByHash', filial, json)

                if (rs.success) {
                    result(rs.data.result.attendance)
                } else {
                    reject(rs.data)
                }
            } catch (error) {
                reject(error)
            }
        })

    cancelByCodeTest = (filial, code, email) => new Promise((result, reject) => setTimeout(() => reject({ message: 'Erro no server' }), 4000))

    cancelByCode = (filial, code, email = null) => new Promise(async (result, reject) => {
        this.isForceRequest = true

        const data = { code, isCancelledByWhatsapp: true }
        if (email) {
            data.email = email
        }

        if (this.currentIP) {
            data.ip = this.currentIP
        }

        const rs = await this.firebase.request('cancelAppoiment', filial, data)
        if (rs.success) {
            result(rs.data)
        } else {
            reject(rs.data)
        }
    })
    
}
import React from 'react'
import { Dialog, DialogTitle as MatDialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '../Button/Button'

const style = {
    topRight: {
        position: 'absolute',
        top: 6,
        right: 4,
    },
}

const DialogTitle = ({ children, onClose }) => (
    <MatDialogTitle>
        {children}
        {onClose ? (
            <IconButton aria-label="Close" onClick={onClose} style={style.topRight}>
                <CloseIcon />
            </IconButton>
        ) : null}
    </MatDialogTitle>
)

const Modal = ({ 
    isVisible, 
    title = 'Camim', 
    yesTitle = 'Sim', 
    noTitle = 'Não', 
    yesButtonColor = 'primary', 
    noButtonColor = 'default', 
    closeButton = false, 
    requesting = false, 
    onConfirm, 
    onCancel, 
    children, 
    ...rest 
}) => (
    <Dialog open={isVisible} onClose={onCancel} {...rest} disableBackdropClick={requesting} disableEscapeKeyDown={requesting}>
        <DialogTitle onClose={closeButton && !requesting ? onCancel : null}>{title}</DialogTitle>
        <DialogContent>
            <div>{children}</div>
        </DialogContent>
        <DialogActions style={{ marginRight: 6, marginBottom: 6, marginTop: 12 }}>
            {!!yesTitle && (
                <Button onClick={onConfirm} color={yesButtonColor} isRequesting={requesting}>
                    {yesTitle}
                </Button>
            )}
            {!!noTitle && !closeButton && (
                <Button onClick={onCancel} disabled={requesting} color={noButtonColor}>
                    {noTitle}
                </Button>
            )}
        </DialogActions>
    </Dialog>
)

export default Modal

export default {
    APP_NAME: 'Camim',
    SQL_MASK: 'YYYY-MM-DD HH:mm',
    SQL_FULL_DATETIME_MASK: 'YYYY-MM-DD HH:mm:ss',
    SQL_DATE_MASK: 'YYYY-MM-DD',
    BR_DATE: 'DD/MM/YYYY',
    BR_MONTHYEAR: 'MMYYYY',
    BR_TIME: 'HH:mm',
    BR_DATETIME: 'DD/MM/YYYY HH:mm',
    FIREBASE_KEY_DATETIME: 'YYYYMMDDHHmmss',
    TIMEOUT_LOGIN: 15,
    INTERNAL_PASSWORD_KEY: 'internalPsw',
    BYPASS_CREDENTIALS: { code: 'N', idCliente: 11400},
    GOOGLE_MAPS_API: 'AIzaSyD4AXc12ZAV_MnZiNXpvsMySocf0PefBxw',
}

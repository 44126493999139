import { connect } from 'react-redux'
import { memoryMapStateToProps, memoryMapDispatchToProps } from './MemoryDuck'
import { formMapStateToProps, formMapDispatchToProps } from './FormDuck'
import camimFirebase from '../../services/CamimFirebase'
import moment from 'moment'

const StoreAction = {
    login: 'login',
    updateUser: 'updateUser',
    logout: 'logout',
}

const initialState = {}

const StoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case StoreAction.login:
            return { ...state, user: action.payload, lastLogin: moment().format() }
        case StoreAction.updateUser:
            return { ...state, user: { ...state.user, ...action.payload } }
        case StoreAction.logout:
            return { ...state, user: null, lastLogin: null }
        default:
            break
    }
    return {...state}
}

const storeMapStateToProps = state => {
    const store = state.store || {}
    const { user: originalUser, lastLogin } = store

    // const spamTime = (!!user && !!user.lastDate && moment().diff(moment(user.lastDate), 's')) || 1000
    // console.log('spamTime')
    // if (spamTime > 20) {
    //     user = null
    // }

    const mustLogin = !originalUser

    const user = mustLogin ? null : {
        ...originalUser,
        ...(originalUser.dependente || {})
    }

    const initialCPF = ((!user ? { cpf: '' } : user.dependente || user).cpf || '')
        .substr(0, 6)

    const fullName = mustLogin ? '' : user.dependente ? (user.dependente.nomeSocial || user.dependente.nome) : (user.nomeSocial || user.nome)

    return {
        ...state,
        user,
        initialCPF,
        mustLogin,
        lastLogin,
        fullName
    }
}

const storeMapDispatchToProps = dispatch => ({
    dispatch,
    login: user => {
        dispatch({ type: StoreAction.login, payload: user })
        camimFirebase.setUser(user)
    },
    updateUser: data => dispatch({ type: StoreAction.updateUser, payload: data }),
    logout: () => {
        dispatch({ type: StoreAction.logout })
        camimFirebase.setUser(null)        
    },
})

const StoreConnectRedux = (mapStateToProps, mapDispatchToProps) => {
    const mstp = state => ({
        ...storeMapStateToProps(state),
        ...memoryMapStateToProps(state),
        ...(mapStateToProps ? mapStateToProps(state) : {}),
    })

    const mdtp = dispatch => ({
        ...storeMapDispatchToProps(dispatch),
        ...memoryMapDispatchToProps(dispatch),
        ...(mapDispatchToProps ? mapDispatchToProps(dispatch) : {}),
    })

    return connect(
        mstp,
        mdtp
    )
}

const StoreFormConnectRedux = (mapStateToProps, mapDispatchToProps) => {
    const mstp = state => ({
        ...formMapStateToProps(state),
        ...(mapStateToProps ? mapStateToProps(state) : {}),
    })

    const mdtp = dispatch => ({
        ...formMapDispatchToProps(dispatch),
        ...(mapDispatchToProps ? mapDispatchToProps(dispatch) : {}),
    })

    return StoreConnectRedux(
        mstp,
        mdtp
    )
}

const withRedux = StoreFormConnectRedux()

export { StoreReducer, StoreConnectRedux, StoreFormConnectRedux, storeMapStateToProps, storeMapDispatchToProps, withRedux }

import React from 'react'
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import { Page, Title, Input, FormCommand, Button } from '../../components'
import InformEmailService from '../../services/InformEmailService'
import { withAuth } from '../../utils/withAuth'
import ReactRecaptcha from 'react-recaptcha'

class InformEmail extends React.Component {
    state = {
        noEmail: false
    }

    constructor(props) {
        super(props)
        props.setPageTitle()
        props.setForm('data', {})
    }

    onLoadRecaptcha = () => {
        if (this.captchaDemo) {
            this.captchaDemo.reset()
            this.captchaDemo.execute()
        }
    }

    verifyCallback = recaptchaToken => {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, '<= your recaptcha token')
    }

    get informEmailService() {
        if (!this._informEmailService) {
            this._informEmailService = new InformEmailService(this.props)
        }
        return this._informEmailService
    }

    submit = async () => {
        try {
            this.setState({ isRequesting: true })
    
            await this.userService.submit()        
    
            this.setState({ isRequesting: false })
    
        } catch (error) {
            console.log('error', error)
            this.setState({ isRequesting: false })
        }   
    }

    setRecaptcha = value => console.log('recaptcha', value)

    canSubmit = () => {
        const { noEmail } = this.state
        if (!noEmail) {
            const { email, repeatEmail } = this.props.getForm('data')
            if (!email) {
                return {
                    disabled: true,
                    message: 'É necessário informar o email',
                }
            } else if (!repeatEmail) {
                return {
                    disabled: true,
                    message: 'É necessário informar o email de verificação',
                }
            }
            if (email !== repeatEmail) {
                return {
                    disabled: true,
                    message: 'Os emails informados não coincidem. Retifique-os',
                }
            }
        }

        // return {
        //     disabled: true,
        //     message: 'Necessário realizar a verificação',
        // }
        return {
            disabled: false,
            message: 'Salvar Email',
        }
        // return {
        //     disabled: true,
        //     message: 'Os emails informados não coincidem. Retifique-os'
        // }
    }

    render = () => {
        const { isMobile } = this.props
        const { noEmail, isRequesting = false } = this.state
        const canSubmit = this.canSubmit()
        return (
            <Page title="Informar Email" card>
                <form autoComplete='off' onSubmit={this.submit}>
                    <Grid container spacing={4}>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Title variant="h6" success underline>
                                Informe o Seu Email
                            </Title>

                            <Grid container spacing={2} style={{ marginTop: 8 }}>
                                <Grid item xs={12}>
                                    <Input 
                                        title="Email" 
                                        placeholder="Informe o email" 
                                        field="data.email" 
                                        type="email" 
                                        disabled={noEmail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        title="Repita seu email" 
                                        placeholder="Informe o email novamente" 
                                        field="data.repeatEmail" 
                                        type="email"
                                        disabled={noEmail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox color='primary' checked={noEmail} onChange={() => this.setState({ noEmail: !noEmail })} value="noEmail" />}
                                        label={isMobile ? 'Não informar meu email' : "Não quero informar meu email"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Title variant="h6" success underline>
                                Verificação
                            </Title>
                            <br />
                            <ReactRecaptcha
                                sitekey='6Lf1qzsUAAAAAGetO1igeOAkzkwg7pIf65qEjvnD'
                                verifyCallback={this.setRecaptcha}
                                onloadCallback={this.setRecaptcha}
                            />
                        </Grid>
                    </Grid>

                    {!isMobile && <FormCommand
                        actionTitle={canSubmit.message}
                        disabled={canSubmit.disabled}
                        isRequesting={isRequesting}
                    />}
                    {isMobile && (
                        <Grid container style={{ marginTop: 12 }}>
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Button
                                    disabled={canSubmit.disabled}
                                    isRequesting={isRequesting}
                                >
                                    {canSubmit.message}
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                </form>
            </Page>
        )
    }
}

export default withAuth(InformEmail)

import React from 'react'
import { Page, FormCommand, Title, Input, Card } from '../../components' 
import { Grid } from '@material-ui/core'
import LoginService from '../../services/LoginService'
import { utils } from '../../utils'
import img_talao_exemplo_senha from '../../assets/images/talao-exemplo-senha.png'
import '../../assets/styles/css/login.css'

class LoginExam extends React.Component {
    state = { }

    constructor(props) {
        super(props)
        props.setForm('login', {})
    }

    get loginService() {
        if (!this._loginService) {
            this._loginService = new LoginService(this.props)
        }
        return this._loginService
    }

    login = async evt => {
        evt.preventDefault()
        try {
            
            const { talao, password } = this.props.getForm('login')
            const code = talao.replace(/[0-9]/g, '')
            const filial = this.props.filials.find(e => e.code === code)
            if (!filial) {
                return this.setState({ error: 'Posto não encontrado' })
            }
            
            this.setState({ isRequesting: true })
            await this.loginService.loginExam(filial, { numeroTalao: talao.replace(/[^0-9]/g, ''), password })        
            this.setState({ isRequesting: false })
    
        } catch (error) {
            console.log('error', error)
            this.setState({ isRequesting: false, error: error.error })
        }   
    }

    canLogin = () => {
        if (!this.props.filials) {
            return false
        }

        const { talao = '', password = '' } = this.props.getForm('login')
        if (talao.trim() === '' || password.trim().length < 3) {
            return false
        }

        const letter = talao.replace(/[0-9]/g, '')
        if (letter.length !== 1) {
            return false
        }

        const filials = this.props.filials.map(e => e.code.toUpperCase())
        if (!filials.includes(letter.toUpperCase())) {
            return false
        }

        const psd = `${talao.replace(/[^0-9]/g, '')}${letter}`
        if (psd !== talao) {
            return false
        }
        return true
    }
    
    render = () => {
        const { error, isRequesting = false } = this.state
        return (
            <Page title='Resultado de Exames' hideBreadcrumb error={error} onDismissError={() => this.setState({ error: null })}>
                <Grid container>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <Card>
                            <Title success underline>Resultado de Exames</Title>

                            <form className="login-form" autoComplete="off" onSubmit={this.login}>
                                <Input 
                                    title="Número do talão" 
                                    placeholder="Entre com o número do talão" 
                                    field="login.talao" 
                                    style={{ marginBottom: 12 }} 
                                    onFormatValue={utils.allowMatricula(this.props.filials)}
                                />

                                <Input 
                                    title="Senha" 
                                    placeholder="Informe a senha com 6 dígitos" 
                                    type="password" 
                                    field="login.password" 
                                    onFormatValue={utils.onlyNumbers}
                                />
                                <br />
                                <br />
                                <FormCommand
                                    actionTitle='Acessar'
                                    disabled={!this.canLogin()}
                                    isRequesting={isRequesting}
                                />
                                <div className='destaque destaqueCenter'>
                                    <div>
                                        Veja abaixo o modelo do recibo e localize o número do talão e a senha.
                                    </div>
                                    <img src={img_talao_exemplo_senha} alt='Exemplo de Talão' />
                                </div>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </Page>
        )
    }
}


export default LoginExam

    
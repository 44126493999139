import React from 'react'
import { Page, Input, FormCommand, Title, Alert, AttendanceType } from '~/components' 
import { withAuth, utils } from '~/utils'
import AppointmentView from './Other/AppointmentView'
import AppointmentService from '~/services/AppointmentService'

class CancelAppointment extends React.Component {
    state = { isRequesting: false }

    constructor(props) {
        super(props)
        props.setForm('appt', {})
    }

    get appointmentService() {
        if (!this._appointmentService) {
            this._appointmentService = new AppointmentService(this.props)
        }
        return this._appointmentService
    }

    canCancel = () => {

        if (this.state.isRequesting) {
            return this.props.isMobile ? 'Cancelando...' : 'Cancelando agendamento...'
        }

        const { password } = this.props.getForm('appt')
        if (utils.isEmpty(password)) {
            return 'Entre com sua senha'
        }
        else if (utils.createCrypto(password) !== this.props.user.crypt) {
            return 'Senha incorreta'
        }
        return null
    }

    cancel = async evt => {
        evt.preventDefault()
        try {
            const { appointment, coordinate } = this.state
            const filial = this.props.filials.find(e => e.code === appointment.filialCode)
            this.setState({ isRequesting: true })
    
            await this.appointmentService.cancel(filial, appointment, coordinate)        
    
            this.setState({ isRequesting: false }, () => this.props.history.replace('/consultas-agendadas'))
    
        } catch (error) {
            console.log('error', error)
            this.setState({ isRequesting: false })
        }   
    }

    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        
        if (!!location) {
          location.getCurrentPosition(
            ({ coords: coordinate }) => this.setState({ coordinate }),
            error => {}
          )
        }
    }

    componentDidMount = () => {
        const { appointment } = this.props
        if (!appointment) {
            return this.props.history.replace('/consultas-agendadas')
        }
        // this.props.removeAppointment(appointment)
        this.setState({ appointment })
        this.getMyLocation()
    }
    
    render = () => {
        const { appointment, isRequesting = false } = this.state
        if (!appointment) {
            return <div />
        }
        const { isMobile } = this.props
        const canCancel = this.canCancel()
        return (
            <Page 
                card 
                title='Cancelar Agendamento'
                breads={[
                    { title: 'Consultas Agendadas', link: '/consultas-agendadas' }
                ]}
            >
                <Title success underline>Cancelar Agendamento</Title>
                <Alert danger center>
                    Para concluir o cancelamento desta consulta, verifique os dados e informe sua senha
                </Alert>
                <AttendanceType topRight isOnline={appointment.isOnline}>
                    <form autoComplete='off' onSubmit={this.cancel}>
                        <AppointmentView 
                            appointmentView={appointment}
                        />
                        <br />
                        <Input 
                            title="Informe sua senha" 
                            placeholder="Informe a senha com 6 dígitos numéricos" 
                            type="password" 
                            maxLength="6" 
                            field="appt.password" 
                            onFormatValue={utils.limitPassword}
                        />
                        <FormCommand
                            hasBack
                            isRequesting={isRequesting}
                            onLeftButtonClick={() => this.props.history.goBack()}
                            leftTitle={isMobile ? 'Voltar' : 'Permanecer com o agendamento'}
                            actionTitle={canCancel || (isMobile ? 'Cancelar' : 'Cancelar o Agendamento')}
                            actionButtonColor='secondary'
                            disabled={!!canCancel}
                        />
                    </form>
                </AttendanceType>
            </Page>
        )
    }
}

export default withAuth(CancelAppointment)

    
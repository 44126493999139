import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import FirebaseConfig from '~/constants/FirebaseConfig'
import { Observable } from 'rx'

export default class {
    constructor() {
        if (app.apps.length === 0) {
            app.initializeApp(FirebaseConfig)
        }

        const auth = app.auth()
        auth.signInAnonymously()
            .then(() => {})
            .catch(() => {})

        this.fb = app
            .firestore()
            .collection('environments')
            .doc('production')
    }

    additionalNode = null
    listenerOn = null

    mainNode = 'filials'

    // collection -> node -> collection ...
    fbNode = node => {
        const items = node.split('/')
        let fb = this.fb
        for (const [index, item] of items.entries()) {
            if (index % 2 === 0) {
                fb = fb.collection(item)
            } else {
                fb = fb.doc(item)
            }
        }
        return fb
    }

    once = node => Observable.create(async emitter => {
        try {
            const snap = await this.fbNode(node).get()
            let data = null
            if (snap.docs) {
                data = snap.docs.map(x => x.data())
            }
            else {
                data = snap.data()
            }
            emitter.onNext(data)
        } catch (error) {
            emitter.onError({ error: error.message })            
        }
    })

    on = node =>
        Observable.create(emitter => {
            this.listenerOn = this.fbNode(node).onSnapshot(snap => {
                if (snap?.exists) {
                    emitter.onNext(snap.data())
                }
            })
        })

    off = () => {
        if (!this.listenerOn) {
            this.listenerOn()
        }
        this.listenerOn = null
    }

    set = (node, value) => this.fbNode(node).set(value)
    delete = node => this.fbNode(node).delete()

    onQuery = ({ 
        node, 
        onConfigure, 
        callback = () => {}
    }) => {
        const fsItem = this.fbNode(node)
        const fsQuery = onConfigure ? onConfigure(fsItem) : fsItem
        return fsQuery.onSnapshot(querySnap => {
            const items = []
            querySnap.forEach(snap => {
                items.push({ ...snap.data(), _docId: snap.id })
            })
            callback(items)
        })
    }
}

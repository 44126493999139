import React from 'react'
import Title from '../Title/Title'
import { Card as MatCard, CardContent } from '@material-ui/core'

const Card = ({ title, children }) => (
    <MatCard>
        <CardContent>
            {!!title && <Title success underline>{title}</Title>}
            {children}
        </CardContent>
    </MatCard>
)

export default Card

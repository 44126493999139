import Axios from 'axios'
import Config from '../constants/Config'

export class ApiService {

    mustInsertToken = false
    isUploadingFile = false

    baseURL = () => Config.apiURL

    get axios() {
        if (!!this._axios) {
            return this._axios
        }

        const config = {
            baseURL: this.baseURL(),
            headers: { Accept : 'application/json' }
        }

        this._axios = Axios.create(config)

        if (!Config.isProduction) {
            this._axios.interceptors.request.use(config => {
                console.log('request', config.baseURL + config.url, config.data)
                console.log('config', config)
                return config
            })

            this._axios.interceptors.response.use((response, error) => {
                console.log('responseError', error)
                console.log('responseStatus', response.status)
                console.log('response', response.data)
                return response
            })
        }

        return this._axios
    }

    setToken = token => {
        this.token = token
        return this
    }

    setUploadFile = () => {
        this.isUploadingFile = true
        return this
    }

    removeToken = () => this.setToken(null)

    get axiosInstance() {
        let headers = this.axios.defaults.headers.common
        if (this.mustInsertToken && !!this.token) {
            headers['Authorization'] = `Bearer ${this.token}`
        }
        else {
            delete headers.Authorization
        }

        if (this.isUploadingFile) {
            headers['Content-Type'] = 'multipart/form-data'            
        }
        else {
            headers['Content-Type'] = 'application/json'            
        }

        this.axios.defaults.headers.common = headers
        this.mustInsertToken = false
        this.isUploadingFile = false
        return this.axios
    }

    changeURI = uri => uri

    auth = () => {
        this.mustInsertToken = true
        return this
    }

    createQueryString = qs => {
        if (!qs) {
            return ''
        }

        const items = Object.keys(qs).map(key => (qs[key] instanceof Array) ? qs[key].map(x => `${key}[]=${x}`).join('&') : `${key}=${qs[key]}`)

        return `?${items.join('&')}`
    }

    dataToSubmit = data => {
        if (!data) {
            return {}
        }

        if (!data.file && !data.imagem && !data.image && !data.avatar && !data.image_url) {
            return data
        }
        
        // const fields = ['imagem', 'image', 'avatar', 'image_url', 'file']
        const form = new FormData();

        if (data.file) {
            form.append('file', {
                uri: data.file,
                type: 'image/jpeg',
                name: 'file.jpg',
            })
            this.isUploadingFile = true
        }

        return form
    }

    manageError = error => ({
        ...error,
        // message: utils.errorMessage(error)
    })
    
    get = (uri, queryString) => this.manageResponse(this.axiosInstance.get(this.changeURI(`${uri}${this.createQueryString(queryString)}`)))
    post = (uri, data) => this.manageResponse(this.axiosInstance.post(uri, data))
    put = (uri, data) => this.manageResponse(this.axiosInstance.put(uri, data))
    patch = (uri, data) => this.manageResponse(this.axiosInstance.patch(uri, data))
    delete = (uri, queryString) => this.manageResponse(this.axiosInstance.delete(this.changeURI(`${uri}${this.createQueryString(queryString)}`)))

    manageResponse = axiosMethod => axiosMethod
        .then(response => response.data)
        .catch(err => {
            const error = this.manageError(err)
            return Promise.reject(error)
        })
}

export const api = new ApiService()
export const authApi = api.auth

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Table as MatTable, TableBody, TableCell as MatTableCell, TableHead, TableRow } from '@material-ui/core'
import Alert from '../Alert/Alert'

const TableCell = withStyles(theme => ({
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    body: {
        fontSize: 13,
    },
}))(MatTableCell)

const renderRow = (headers, row, index, rowClassName) => (
    <TableRow key={index} className={rowClassName}>
        {headers.map((header, indexHeader) => (
            <TableCell key={(index + 1) * 100 + indexHeader}>
                {!!header.render ? header.render(row) : row[header.field]}
            </TableCell>
        ))}
    </TableRow>
)

const renderRequesting = (cols, isRequesting, requestingMessage) => {
    if (!isRequesting) {
        return null
    }
    
    return (
        <TableRow>
            <TableCell colSpan={cols}>
                <Alert warning requesting>
                    {requestingMessage}
                </Alert>
            </TableCell>
        </TableRow>
    )
}

const Table = ({ headers = [], rows = [], widths = [], classes, isRequestingBeforeRows = true, requesting = false, requestingMessage, emptyMessage, rowClassName, columnStyle }) => (
    <MatTable>
        <TableHead>
            <TableRow>
                {headers.map((header, index) => (
                    <TableCell key={index} className={header.className || ''} component="th" style={!!columnStyle ? columnStyle(index, header) : {}}>
                        {header.title}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {isRequestingBeforeRows && renderRequesting(headers.length, requesting, requestingMessage)}
            {rows.map((row, index) => renderRow(headers, row, index, !rowClassName ? '' : rowClassName(row) ))}
            {!isRequestingBeforeRows && renderRequesting(headers.length, requesting, requestingMessage)}
            {!requesting && !!emptyMessage && (
                <TableRow>
                    <TableCell colSpan={headers.length}>
                        <Alert style={{ paddingTop: 16, paddingBottom: 16 }}>
                            {emptyMessage}
                        </Alert>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    </MatTable>
)

export default Table

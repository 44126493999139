export default class ActionResult {
    success = false
    data = null
    error = null
    internalItems = null

    constructor(data, error) {
        this.success = !data ? false : data.success
        this.data = data
        if (!this.data) {
            this.error = error
        }
        else {
            this.error = data.message
        }
    }

    get result() {
        return this.success ? this.data.result : null
    }

    get items() {
        if (!this.success) {
            return []
        }
        else if (this.internalItems != null) {
            return this.internalItems
        }
        else if (this.data == null || this.data.result.items == null) {
            return []
        }

        let json = this.data.result.items
        let items = []

        for (var key in json) {
            if (json.hasOwnProperty(key)) {
                let value = json[key];
                items.push(value);
            }
        }

        return items
    }
}
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#6398A8',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
})

const renderRow = (headers, row, index) => (
    <TableRow key={index}>
        {headers.map((header, indexHeader) => (
            <CustomTableCell key={index}>{row[header.field]}</CustomTableCell>
        ))}
    </TableRow>
)

const Grid = ({ headers = [], rows = [], classes, ...props }) => {
    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    {headers.map((header, index) => (
                        <CustomTableCell key={index} component="th">
                            {header.title}
                        </CustomTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>{rows.map((row, index) => renderRow(headers, row, index))}</TableBody>
        </Table>
    )
}

export default withStyles(styles)(Grid)

import firebase from './CamimFirebase'

export default class {

    constructor(props) {
        this.props = props
        !!props && !!props.user && this.setUser(props.user)
        this.filials = props.filials || []
    } 

    set isForceRequest(value) {
        firebase.isForceRequest = value
    }

    get firebase() {
        return firebase
    }
    
    setUser = user => {
        this.user = user
        this.firebase.setUser(user)
    }

    nextFilial = (code, callback) => {
        let [ nextFilial ] = this.filials
        let currentFilial = null
        
        if (!!code) {
            currentFilial = this.filials.find(e => e.code === code)
            const index = this.filials.indexOf(currentFilial)
            if (index + 1 < this.filials.length) {
                nextFilial = this.filials[index + 1]
            }
            else {
                nextFilial = null
            }
        }

        !!callback && callback(nextFilial)

        return {
            currentFilial,
            nextFilial
        }
    }

}
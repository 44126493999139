import React from 'react'
import { CircularProgress } from '@material-ui/core'
import Card from '../Card/Card'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Modal from '../Modal/Modal'
import { Center } from '../../layout'
import MainContent from './MainContent'
import { StoreConnectRedux } from '../../store/ducks/StoreDuck'
import { Subject } from 'rx'
import Theme from '../../styles/Theme'
import { Snackbar, Button, CssBaseline } from '@material-ui/core'
import '../../assets/styles/css/page.css'

export const logoutObservable = new Subject()

const Content = ({ card, title, children }) => {
    if (!card) {
        return <>{children}</>
    }
    return <Card title={title}>{children}</Card>
}

class Page extends React.PureComponent {
    state = { isShowLogout: false }

    manageWindowSize = () => this.props.setWindowSize(window.innerWidth)

    componentDidMount = () => {
        logoutObservable.subscribe(isShowLogout => this.setState({ isShowLogout }))
        window.addEventListener('resize', this.manageWindowSize)
        this.props.setWindowSize(window.innerWidth)
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.manageWindowSize)

    makeLogout = () => {
        this.props.logout()
        setTimeout(() => {
            window.location.href = 'https://www.camim.com.br'
        }, 200)
    }

    render = () => {
        const { isMobile, isRegularScreen, children, customBreadcrumb, hideBreadcrumb = false, breads = [], title, cardTitle, card = false, error, onDismissError, loading = false } = this.props

        const { isShowLogout } = this.state

        return (
            <Theme>
                <CssBaseline />
                <Modal isVisible={isShowLogout} onConfirm={this.makeLogout} onCancel={() => this.setState({ isShowLogout: false })}>
                    Sair da área administrativa?
                </Modal>
                <Header />
                <MainContent
                    title={title}
                    customBreadcrumb={customBreadcrumb}
                    breadcrumb={loading || hideBreadcrumb ? null : title}
                    breads={breads}
                    isRegularScreen={isRegularScreen}
                    isMobile={isMobile}
                >
                    <Content title={cardTitle} card={card}>
                        {loading && (
                            <Center>
                                <CircularProgress /> <div style={{ marginTop: 8 }}>Obtendo informações...</div>
                            </Center>
                        )}
                        {!loading && children}
                    </Content>
                </MainContent>
                <Footer isMobile={isMobile} />
                <Snackbar
                    open={!!error}
                    onClose={onDismissError}
                    message={<span id="message-id">{error}</span>}
                    action={[
                        <Button key="undo" size="small" className="dismissButton" onClick={onDismissError}>
                            OK
                        </Button>,
                    ]}
                />
            </Theme>
        )
    }
}

export default StoreConnectRedux()(Page)

import FirebaseService from './FirebaseService'
import { utils } from '../utils/Utils'

export default class extends FirebaseService {

    get filial() {
        return this.filials.find(e => e.code === this.user.codeFilial)        
    }

    changePassword = password => this.firebase.request('changePassword', this.filial, { password }).then(() => this.props.updateUser({ crypt: utils.createCrypto(password) }))

}
import React from 'react'
import { Page } from '~/components'
import google from '../../assets/images/google_play_br.png'
import apple from '../../assets/images/app_store_br.png'

const OnlyOnApp = () => {

    return (
        <Page title='Marcar Consulta' card>
            <h3 style={{ textAlign: 'center' }}>AGENDAMENTOS? BAIXE O APP AGORA!</h3>
            <table width='100%'>
                <tr>
                    <td align='center'>
                        <a href="https://play.google.com/store/apps/details?id=com.mrfsolution.camimapp" target="_blank" rel="noopener noreferrer">
                            <img style={{ width: 200 }} src={google} alt="Google Play" />
                        </a>
                    </td>
                    <td align='center'>
                        <a href="https://apps.apple.com/br/app/camim-app/id1019335872" target="_blank" rel="noopener noreferrer">
                            <img style={{ width: 200 }} src={apple} alt="App Store" />
                        </a>
                    </td>
                </tr>
            </table>
        </Page>
    )
}

export default OnlyOnApp

import Axios from 'axios'

export default class {

    constructor() {
        this.axios = Axios.create({
            headers: { Accept : 'application/json' }
        })
    }

    ipInfo = () => this.axios.get('https://ipinfo.io/json').then(({ data }) => {
        const { ip, city, state } = data
        const [ lat, lng ] = data.loc.split(',')

        return {
            ip, 
            city, 
            state,
            lat, 
            lng
        }
    })

}
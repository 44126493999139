import React from 'react'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

const LinkMenu = ({ href, children }) => (
    <div className='itemMenuLink'>
        <KeyboardArrowRightIcon size={16} /> <a style={{ position: 'relative', top: -6, fontFamily: 'Montserrat' }} href={href}>{children}</a>
    </div>
)

export default () => (
    <section id="nav_menu-2" className="fusion-footer-widget-column widget widget_nav_menu">
        <h4 className="widget-title">Menu</h4>
        <div className="menu-menu-principal-container">
            <LinkMenu href="https://wp-homolog.camim.com.br/a-empresa/">A Camim</LinkMenu>
            <LinkMenu href="https://wp-homolog.camim.com.br/especialidades/">Especialidades</LinkMenu>
            <LinkMenu href="https://wp-homolog.camim.com.br/postos/">Postos de atendimento</LinkMenu>
            <LinkMenu href="https://wp-homolog.camim.com.br/blog/">Blog</LinkMenu>
            <LinkMenu href="https://wp-homolog.camim.com.br/trabalhe-conosco/">Trabalhe Conosco</LinkMenu>
            <LinkMenu href="https://wp-homolog.camim.com.br/contato/">Contato</LinkMenu>
            <LinkMenu href="http://clubecamim.com.br/">Clube Camim</LinkMenu>
            <LinkMenu href="https://wp-homolog.camim.com.br/quero-me-associar/">Quero me associar</LinkMenu>
        </div>
        <div className="headerCSS_6" />
    </section>
)

import React from 'react'
import { Page, Input, FormCommand } from '../../components'
import { withAuth } from '../../utils/withAuth'
import AccountService from '../../services/AccountService'
import { utils } from '../../utils/Utils'

class ChangePassword extends React.Component {

    state = {}

    constructor(props) {
        super(props)
        props.setForm('record', {})
    }

    get accountService() {
        if (!this._accountService) {
            this._accountService = new AccountService(this.props)
        }
        return this._accountService
    }

    canSubmit = () => {

        const { user, initialCPF } = this.props

        const { password, repeatPassword } = this.props.getForm('record')
        if (!password || password.trim().length < 6) {
            return {
                disabled: true,
                message: 'A senha deverá conter 6 dígitos numéricos',
            }
        } 
        else if (password === initialCPF) {
            return {
                disabled: true,
                message: 'Não é permitido utilizar as iniciais do seu CPF',
            }
        }
        else if (utils.createCrypto(password) === user.crypt) {
            return {
                disabled: true,
                message: 'A senha não pode ser igual a atual',
            }
        }
        else if (utils.createCrypto(password) === user.cryptBirthday) {
            return {
                disabled: true,
                message: 'A senha não pode ser igual ao mês e ano de nascimento',
            }
        }
        else if (password === (this.props.user.cpf || '').substr(0,6)) {
            return {
                disabled: true,
                message: 'A senha não pode ser os dígitos iniciais do seu CPF',
            }
        }
        else if (!repeatPassword) {
            return {
                disabled: true,
                message: 'Informe a confirmação de senha',
            }
        } 
        else if (password !== repeatPassword) {
            return {
                disabled: true,
                message: 'As senhas não conferem',
            }
        }

        return {
            disabled: false,
            message: 'Realizar alteração da senha',
        }
    }

    submit = async evt => {
        evt.preventDefault()
        try {
            this.setState({ isRequesting: true })
    
            await this.accountService.changePassword(this.props.getFormValue('record.password'))
            this.props.setForm('record', {})
            this.setState({ isRequesting: false, error: 'Senha alterada com sucesso!' })
    
        } catch (error) {
            console.log('error', error)
            this.setState({ isRequesting: false, error: error.error })
        }   
    }

    render = () => {
        const { error, isRequesting = false } = this.state
        const { isMobile } = this.props
        const canSubmit = this.canSubmit()
        return (
            <Page 
                title='Alterar Senha' 
                card 
                cardTitle='Para alterar sua senha, informe os dados abaixo:'
                error={error}
                onDismissError={() => this.setState({ error: null })}
            >

                <form autoComplete='off' onSubmit={this.submit}>
                    <br />
                    <Input 
                        title="Senha" 
                        placeholder="Informe a senha com 6 dígitos numéricos" 
                        type="password" 
                        field="record.password" 
                        fullWidth
                        onFormatValue={utils.limitPassword}
                        cols={12}
                    />

                    <Input 
                        cols={12}
                        title="Confirmação de senha" 
                        placeholder="Confirme sua nova senha" 
                        type="password" 
                        field="record.repeatPassword" 
                        fullWidth
                        onFormatValue={utils.limitPassword}
                    />

                    <FormCommand
                        isMobile={isMobile}
                        actionTitle={canSubmit.message}
                        disabled={canSubmit.disabled}
                        isRequesting={isRequesting}
                    />
                </form>

            </Page>
        )
    }
}

export default withAuth(ChangePassword)

import React from 'react'
import { Tabs as MatTabs, Tab } from '@material-ui/core'

class Tabs extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            index: props.initialIndex || 0
        }
    }

    setTab = (_, index) => this.setState({ index }, () => !!this.props.onPick && this.props.onPick(this.props.items[index], index))

    render = () => {
        const { items, field = 'title' } = this.props
        const { index = 0 } = this.state
        return (
            <MatTabs
                value={index}
                onChange={this.setTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
            >
                {items.map((e, index) => <Tab key={index} label={e[field]} />)}
            </MatTabs>
        )
    }
}

export default Tabs
import styled from 'styled-components'

export const Tag = styled.span`
  padding: 6px 10px;
  border-radius: 16px;
  color: white;
  font-size: 12px;
  background-color: ${props => props.isOnline ? '#007700' : '#00BFA5'};
`

export const TagFloat = styled(Tag)`
  position: absolute;
  top: 0;
  right: 0;
`

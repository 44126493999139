import React from 'react'
import { Relative } from '~/layout'
import { Tag, TagFloat } from './style'

const AttendanceType = ({ isOnline = true, topRight = false, children }) => {

    const Element = topRight ? TagFloat : Tag 

    const content = (
        <Element isOnline={isOnline}>
            {isOnline ? 'Consulta Online' : 'Consulta Presencial'}
        </Element>
    )

    if (!topRight) {
        return content
    }
    
    return (
        <Relative>
            {children}
            {content}
        </Relative>
    )

}

export default AttendanceType

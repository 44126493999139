import sha256 from 'crypto-js/sha256'
import moment from 'moment'
import Mask from '../constants/Mask'

class Utils {
    chunk = (items, size = 3) => Array.from(Array(Math.ceil(items.length/size)), (_,i) => items.slice(i*size,i*size+size))
    formatPrice = value => `R$ ${value.toFixed(2).replace('.', ',')}`
    onlyNumbers = value => (value || '').replace(/[^0-9]/g, '')

    limitPassword = value => this.limitPasswordChars(6)(value)
    limitPasswordChars = size => value => this.onlyNumbers(value).slice(0,size)

    allowMatricula = filials => value => {
        const [ code ] = value.replace(/[0-9]/g, '').toUpperCase().split("")
        const matNumber = value.replace(/[^0-9]/g, '')

        if (!filials.find(e => e.code === code)) {
            return matNumber
        }
        return `${matNumber}${code}`
    }

    isEmpty = value => !value || value.trim() === ''

    isEmailValid = email => {
        if (this.isEmpty(email)) {
            return false
        }
    
        const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!emailPattern.test(email)) {
            return false
        }
    
        const lowerCaseEmail = email.toLowerCase()
        const finalEmail = lowerCaseEmail.replace(/^[0-9]+/,'').replace(/[^a-z0-9._@]/g, '')
    
        return lowerCaseEmail === finalEmail
    }

    createCryptoFromDate = date => this.createCrypto(moment(date).format(Mask.BR_MONTHYEAR))
    createCrypto = value => sha256(value).toString()
}

export const utils = new Utils()
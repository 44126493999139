import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core'

const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2,
    },
  });
  

const MRFSelect = ({ classes, value = '', onChangeValue, placeholder, items = [], noChoose = false, cols, ...rest }) => {
  
  const selec = (
    <Select value={value || ''} onChange={evt => onChangeValue && onChangeValue(evt.target.value)} {...rest} placeholder={placeholder} label={placeholder}>
        {!noChoose && <MenuItem value="">
            <em>(escolha)</em>
        </MenuItem>}
        {items.map((item, index) => (
            <MenuItem key={index} value={item.value}>
                {item.title}
            </MenuItem>
        ))}
    </Select>
  )

  if (!cols) {
      return (
          <FormControl className={classes.formControl}>
              {placeholder && <InputLabel>{placeholder}</InputLabel>}
              {selec}
          </FormControl>
      )
  }

  return (
      <Grid container spacing={3} style={{ marginBottom: 8 }}>
          <Grid item xs={cols}>
              <InputLabel>{placeholder}</InputLabel>
              {selec}
          </Grid>
      </Grid>
  )
}

export default withStyles(styles)(MRFSelect)

import React from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core';
import { Page, Table, Tabs, Alert, Button } from '../../components'
import ExamService from '../../services/ExamService'
import { withAuthExam } from '../../utils/withAuth'
import Mask from '../../constants/Mask'
import '../../assets/styles/css/exam.css'

class Exam extends React.Component {

    state = {
        doctors: []
    }

    get examService() {
        if (!this._examService) {
            this._examService = new ExamService(this.props)
        }
        return this._examService
    }

    groupBy = (field, items) => {
        const uniques = Array.from(new Set(items.map(e => e[field])))
        return uniques.map(item => ({ item, values: items.filter(e => e[field] === item) }))
    }

    toggleShowExams = id => this.setState({ doctors: this.state.doctors.map(e => e.id === id ? ({ ...e, isShowExams: !e.isShowExams }) : e)})

    castDate = date => date.includes('/') ? date : moment(date).format(Mask.BR_DATE)
    castSqlDate = date => date.includes('-') ? date : moment(date, Mask.BR_DATE).format(Mask.SQL_DATE_MASK)

    groupExams = exams => {
        const orderedExams = exams
            .sort((bf, af) => bf.date > af.date ? -1 : 1)
            .map(e => ({...e, sqlDate: e.date, date: moment(e.date).format(Mask.BR_DATE) }))

        let elements = []
        const filials = this.groupBy('codeFilial', orderedExams)
        filials.forEach(fl => {
            const filial = this.props.filials.find(e => e.code === fl.item)
            const dates = this.groupBy('date', fl.values)
            dates.forEach((date, dateIndex) => {
                const doctors = this.groupBy('physician', date.values)
                elements = [...elements, ...doctors.map((doctor, index) => ({
                    id: `${filial.code}_${dateIndex}_${index}`,
                    sqlDate: this.castSqlDate(date.item),
                    date: this.castDate(date.item),
                    intDate: parseFloat(date.item.replace(/-/g,'')),
                    physician: doctor.item,
                    filial,
                    code: filial.code,
                    isShowExams: false,
                    exams: doctor.values
                }))]
            })
        })

        return elements
    }

    print = (doctor, { idLancamentoServico: id, file, codeFilial }) => {
        const url = `https://resultados.camim.com.br:8099/gera_pdf.php?p1=${id}&p2=${file}&p3=${codeFilial}&p4=c:\\dados\\camim&p5=pdf`
        const { doctors } = this.state
        doctor.printingId = id
        this.setState({ doctors: doctors.map(e => e.id === doctor.id ? doctor : e) })
        setTimeout(() => {
            doctor.printingId = 0
            this.setState({ doctors: doctors.map(e => e.id === doctor.id ? doctor : e) })
        }, 3000)
        // document.location.href = url
        window.open(url)
    }

    renderPrintButton = doctor => item => (
        <Button isRequesting={(doctor.printingId === item.idLancamentoServico)} onClick={() => this.print(doctor, item)} disabled={item.situation !== 'Normal'}>
            Imprimir
        </Button>
    )

    renderTable = item => this.props.isMobile ? this.renderSmall(item) : this.renderLarge(item)

    renderSmall = e => (
        <div className='groupExams'>
            <Grid container>
                <Grid item xs={12}>
                    Data:<br />
                    <strong>{e.date}</strong><br />
                    Solicitante:<br />
                    <div><strong>{e.physician}</strong></div>
                    Posto:<br />
                    <strong>{e.filial.name}</strong>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button color={e.isShowExams ? 'default' : 'primary'} onClick={() => this.toggleShowExams(e.id)}>
                        {e.isShowExams ? 'Ocultar exames' : 'Exibir exames'}
                    </Button>
                </Grid>
            </Grid>

            {e.isShowExams && (
                <>
                <br />
                <Grid container className="oddRow">
                    <Grid item xs={12}>
                        Exame
                    </Grid>
                </Grid>
                
                {e.exams.map((exam, index) => (
                    <Grid key={index} container className={index % 2 === 0 ? 'evenRow' : 'oddRow'}>
                        <Grid item xs={12}>
                            <div><strong>{exam.service}</strong></div>
                            <div>Talão: {exam.idLancamento}</div>
                            <div>Data da realização: {exam.date}</div>
                            <div>Status: {exam.situation === 'Normal' ? 'OK' : exam.situation}</div>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            {this.renderPrintButton(e)(exam)}
                        </Grid>
                    </Grid>
                ))}
                </>
            )}
        </div>
    )

    renderLarge = e => (
        <div className='groupExams'>
            <Grid container>
                <Grid item xs={2}>
                    Data:<br />
                    <strong>{e.date}</strong>
                </Grid>
                <Grid item xs={6}>
                    Solicitante:<br />
                    <strong>{e.physician}</strong>
                </Grid>
                <Grid item xs={2}>
                    Posto:<br />
                    <strong>{e.filial.name}</strong>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <Button color={e.isShowExams ? 'default' : 'primary'} onClick={() => this.toggleShowExams(e.id)}>
                        {e.isShowExams ? 'Ocultar exames' : 'Exibir exames'}
                    </Button>
                </Grid>
            </Grid>
            {e.isShowExams && (
                <Table 
                    headers={[
                        { title: 'Talão', field: 'codigo' },
                        { title: 'Exame', field: 'service' },
                        { title: 'Data da Realização', field: 'date' },
                        { title: 'Status', render: ({ situation }) => situation === 'Normal' ? 'OK' : situation },
                        { title: 'Imprimir', className: 'command1_5x', render: this.renderPrintButton(e) },
                    ]}
                    rows={e.exams}
                />
            )}
        </div>
    )

    componentDidMount = () => {
        let exams = []
        this.subscriber = this.examService.exams(filial => this.setState({ filial })).subscribe(
            items => exams = [...exams, ...items],
            err => {},
            () => this.setState({ doctors: this.groupExams(exams) })
        )
    }

    componentWillUnmount = () => !!this.subscriber && this.subscriber.dispose()

    render = () => {
        const { filials, mustLogin, isMobile } = this.props
        const { filial, doctors: listDoctors, pickedFilial } = this.state
        const doctors = listDoctors.filter(e => !pickedFilial || e.code === pickedFilial).sort((bf, af) => bf.sqlDate > af.sqlDate ? -1 : 1)
        return (
            <Page 
                title='Resultado de Exames' 
                cardTitle={mustLogin && 'Resultado de Exames'}
                card 
                hideBreadcrumb={mustLogin}
            >
                {mustLogin && <br />}
                <Tabs 
                    items={[
                        { id: 0, name: 'Todos os postos' },
                        ...filials
                    ]}
                    field='name'
                    onPick={({ code: pickedFilial }) => this.setState({ pickedFilial })}
                />

                {!!filial && (
                    <Alert success requesting>
                        {!isMobile ? 'Obtendo informações do posto de' : 'Buscando em'} {filial.name}
                    </Alert>
                )}

                {!filial && (doctors.length === 0) && (
                    <Alert>
                        Não há exames neste posto
                    </Alert>
                )}

                {doctors.map((e, index) => (
                    <React.Fragment key={index}>
                        {this.renderTable(e)}
                    </React.Fragment>
                ))}
            </Page>
        )
    }
}

export default withAuthExam(Exam)

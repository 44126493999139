import React from 'react'
import { Page, Title, Button, Input } from '../../components' 
import { Grid } from '@material-ui/core'
import { StoreFormConnectRedux } from '../../store/ducks/StoreDuck'
import { utils } from '../../utils/Utils'
import AccountService from '../../services/AccountService'

class ForceChangePassword extends React.Component {
    state = { }

    constructor(props) {
        super(props)
        props.setForm('changePassword', {})
    }

    get accountService() {
        if (!this._accountService) {
            this._accountService = new AccountService(this.props)
        }
        return this._accountService
    }

    changePassword = async () => {
        this.setState({ isRequesting: true })
        try {
            const { password } = this.props.getForm('changePassword')
            await this.accountService.changePassword(password)
        } catch (error) {
            
        }
        this.setState({ isRequesting: false })
    }

    canSubmit = () => {
        const { password, repeatPassword } = this.props.getForm('changePassword')
        if (!password || password.trim().length < 6) {
            return {
                disabled: true,
                title: 'A senha deverá conter 6 dígitos numéricos',
            }
        } else if (!repeatPassword) {
            return {
                disabled: true,
                title: 'Informe a confirmação de senha',
            }
        } else if (password !== repeatPassword) {
            return {
                disabled: true,
                title: 'As senhas não conferem',
            }
        }

        return {
            disabled: false,
            title: 'Realizar alteração da senha',
        }
    }

    render = () => {
        const { isRequesting = false } = this.state
        const { translate } = this.props

        const canSubmit = this.canSubmit()

        return (
            <Page card title='Alterar Senha'>
                <Title success>
                    {translate('ans_message', 'Por determinação da ANS, é necessário alterar a sua senha de acesso ao site:')}
                </Title>

                <p>
                    <strong>Para alterar sua senha informe os dados abaixo:</strong>
                </p>

                <Input 
                    title="Nova senha" 
                    placeholder="Informe a senha com 6 dígitos numéricos" 
                    type="password" 
                    maxLength={6}
                    field="changePassword.password" 
                    fullWidth
                    onFormatValue={utils.limitPassword}
                    cols={12}
                />
                
                <Input 
                    title="Confirmação de nova senha" 
                    placeholder="Confirme sua nova senha" 
                    type="password" 
                    maxLength={6} 
                    field="changePassword.repeatPassword" 
                    fullWidth
                    onFormatValue={utils.limitPassword}
                    cols={12}
                />

                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button type="button" onClick={this.changePassword} tabIndex="-1" isRequesting={isRequesting} disabled={isRequesting || canSubmit.disabled} color='primary'>
                            {canSubmit.title}
                        </Button>
                    </Grid>
                </Grid>
            </Page>
        )
    }
}

export default StoreFormConnectRedux()(ForceChangePassword)

    
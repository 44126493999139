import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Button from '../Button/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const FormCommand = ({ isMobile = false, children, to, hasBack = false, onLeftButtonClick, onActionButtonClick, leftColor = 'secondary', leftVariant = 'text', leftTitle = 'Voltar', actionTitle = 'Confirmar', actionButtonColor = 'primary', disabled = false, isRequesting = false }) => (
    <Grid container style={{ marginTop: 16 }}>
        <Grid item xs={isMobile ? 3 : 6}>
            {!!to && (
                <Link to={to}>
                    <Button type="button" tabIndex="-1" variant="text" disabled={isRequesting} className="sectionColor btn-forgot">
                        {hasBack && <ArrowBackIosIcon />}
                        {leftTitle}
                    </Button>
                </Link>
            )}
            {!!onLeftButtonClick && (
                <Button type="button" onClick={onLeftButtonClick} tabIndex="-1" color={leftColor} variant={leftVariant} disabled={isRequesting} className="sectionColor btn-forgot">
                    {hasBack && <ArrowBackIosIcon />} {leftTitle}
                </Button>
            )}
            {children}
        </Grid>
        <Grid item xs={isMobile ? 9 : 6} style={{ textAlign: 'right' }}>
            <Button type={!!onActionButtonClick ? 'button' : 'submit'} onClick={onActionButtonClick} tabIndex="-1" isRequesting={isRequesting} disabled={isRequesting || disabled} color={!disabled ? actionButtonColor : 'secondary'}>
                {actionTitle}
            </Button>
        </Grid>
    </Grid>
)

export default FormCommand
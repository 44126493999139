import FirebaseService from './FirebaseService'
import { api } from './ApiService'
import moment from 'moment'
import Mask from '../constants/Mask'

export default class extends FirebaseService {
    finance = filial =>
        this.firebase.request('finance_v2', filial).then(({ items }) => items.sort((bf, af) => (moment(bf.dtReference, Mask.BR_DATE).isAfter(moment(af.dtReference, Mask.BR_DATE)) ? -1 : 1)))

    createInvoice = async (filial, idReceitas) => {
        const { success, result } = await this.firebase.request('generateInvoice', filial, { idReceitas }, true)
        if (!success || !result.endpoint) {
            return Promise.reject({ success: false })
        }

        return { success: true, endpoint: result.endpoint }
    }

    boleto = (finance, banco) =>
        api.post(`boleto/store/${banco}`, {
            documento: this.user.cpf,
            nome: this.user.nome,
            cep: this.user.cep,
            endereco: this.user.endereco,
            bairro: this.user.bairro,
            cidade: this.user.cidade,
            uf: this.user.estado,
            valor: finance.value,
            vencimento: moment(finance.dtVencto, Mask.BR_DATE).format(Mask.SQL_DATE_MASK),
            matricula: this.user.matricula,
            codeFilial: this.user.codeFilial,
            idReceita: finance.idReceita,
            nossoNumero: finance.nossoNumero,
            service: finance.service,
            subservice: finance.subservice,
        })
}

export const checkPayment = id => api.get(`check-payment/${id}`)

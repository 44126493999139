import React from 'react'
import { Page, logoutObservable } from '../../components'
import DashboardService from './DashboardService'
import chunk from 'lodash/chunk'
import { Grid } from '@material-ui/core'
import { withAuth } from '../../utils/withAuth'
import { NavLink } from 'react-router-dom'
import Routes from '../../routes/Routes'
import '../../assets/styles/css/dashboard.css'

const NavRoute = ({ children, path }) => (
    <NavLink to={path} className="linkMenu">
        {children}
    </NavLink>
)

const Link = ({ children, link }) => (
    <a href={link} className="linkMenu">
        {children}
    </a>
)

const Item = ({ children }) => (
    <div>
        {children}
    </div>
)

const ItemButton = ({ children, ...props }) => (
    <button type='button' {...props} className="iconExit">
        {children}
    </button>
)

class Dashboard extends React.PureComponent {

    generateIcons = user => {
        const items = [
            ...Routes.filter(({ hidden, forbiddenDependent }) => {
                if (hidden) {
                    return false
                }
                else if (forbiddenDependent && !!user.idDependente) {
                    return false
                }
                return true
            }),
            { 
                title: 'Sair', 
                icon: 'ic_shutdown.png',
                onClick:() => logoutObservable.onNext(true)
            },
        ] 

        const chunks = chunk(items, this.props.isMobile ? 2 : 4)
        const lastIndex = chunks.length - 1
        const lastChunk = chunks[lastIndex]
        if (lastChunk.length === 4) {
            return chunks
        }

        const remain = Array(4 - lastChunk.length).fill({ isEmpty: true })

        const lastItem = [...lastChunk, ...remain]

        return chunks.map((e, index) => index === lastIndex ? lastItem : e)
    }

    get service() {
        if (!this.service) {
            this.service = new DashboardService(this.props)
        }
        return this.service
    }

    renderIcon = icon => {
        if (icon.isEmpty) {
            return null
        }
        
        const Element = icon.link ? Link : icon.path ? NavRoute : icon.onClick ? ItemButton : Item

        const item = (
            <>
                <img className="icon" src={require(`../../assets/icons/${icon.icon}`)} alt={icon.title} />
                <div className="title">{icon.title}</div>
            </>
        )
        if (!!icon.href) {
            return (
                <a {...icon}>
                    {item}
                </a>
            )
        }

        return (
            <Element {...icon}>
                {item}
            </Element>
        )
    }

    render = () => {
        const { isMobile, user } = this.props
        const icons = this.generateIcons(user)
        return (
            <Page hideBreadcrumb title={isMobile ? 'Painel' : 'Área Administrativa'}>
                {icons.map((chk, indexChk) => (
                    <Grid container key={indexChk} className={indexChk > 0 ? 'iconSection' : ''}>
                        {chk.map((icon, index) => (
                            <Grid item xs={!isMobile ? 3 : 6} style={{ textAlign: 'center' }} key={indexChk * 10 + index} className="iconMenu">
                                {this.renderIcon(icon)}
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Page>
        )
    }
}

export default withAuth(Dashboard)

import React from 'react'
import MaskedInput from 'react-text-mask'
import { TextField } from '@material-ui/core'

const masks = {
    phone: ['(', /[1-9]/, /\d/, ')', ' ', /[9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    zipCode: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
}

const MaskTextField = ({ 
    type,
    mask,
    ...props
}) => {

    if (!type && !mask) {
        return null
    }

    const componentMask = masks[type] || mask

    return (
        <MaskedInput
            {...props}
            mask={componentMask}
            placeholderChar={'\u2000'}
            showMask
        />
    )
}

export default ({ label, value, helperText, ...props }) => (
    <TextField
        label={label}
        value={value}
        fullWidth
        error={!!helperText}
        helperText={helperText}
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            inputComponent: MaskTextField,
            inputProps: props,
        }}
    />
)
import React, { useCallback, useEffect } from 'react'
import { StoreConnectRedux } from '~/store/ducks/StoreDuck'
import Routes from '~/routes/Routes'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import FirestoreService from '~/services/Firebase/Firestore'
import WaitingPayment from '../WaitingPayment'

const Container = ({ setConfig }) => {
    const service = useCallback(() => new FirestoreService())

    useEffect(() => {
        service().on('config/web')
            .subscribe(setConfig)
    }, [])

    return (
        <BrowserRouter basename=''>
            <Switch>
                {Routes.filter(e => !!e.path).map((route, index) => (
                    <Route 
                        key={index} 
                        path={route.path} 
                        exact
                        component={route.component}
                    />
                ))}
                <Route 
                    path='/aguardando-pagamento'
                    exact
                    component={WaitingPayment}
                />
                <Route 
                    path='/aguardando-pagamento/:id'
                    exact
                    component={WaitingPayment}
                />
            </Switch>
        </BrowserRouter>
    )
}

export default StoreConnectRedux()(Container)

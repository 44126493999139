import React from 'react'
import { Grid } from '@material-ui/core'
import '../../assets/styles/css/manufacturer.css'
/*
export default () => (
    <footer id="footer" className="fusion-footer-copyright-area fusion-footer-copyright-center">
        <div className="fusion-row">
            <div className="fusion-copyright-content">
                <div className="fusion-copyright-notice">
                    <div>
                        <p style={{ textAlign: 'center' }}>
                            COPYRIGHT ©2018. TODOS OS DIREITOS RESERVADOS. DESENVOLVIDO POR
                            <a className="headerCSS_17" href="https://posittiva.com.br">
                                <b>POSITTIVA</b>
                            </a>
                        </p>
                    </div>
                </div>
                <div className="fusion-social-links-footer" />
            </div>
        </div>
    </footer>
)
*/

export default () => (
    <Grid container className='manufacturer'>
        <Grid item xs={12} className='container'>
            COPYRIGHT ©2018. TODOS OS DIREITOS RESERVADOS. DESENVOLVIDO POR <a className="link" href="https://posittiva.com.br">
                <b>POSITTIVA</b>
            </a>
        </Grid>
    </Grid>
)
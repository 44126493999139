import React from 'react'
import { Page, Table, Button, Alert, AttendanceType } from '~/components'
import { Grid } from '@material-ui/core'
import { withAuth } from '~/utils/withAuth'
import AppointmentService from '~/services/AppointmentService'
import Config from '~/constants/Config'
import '~/assets/styles/css/table.css'

class Appointment extends React.PureComponent {

    state = {
        appointments: []
    }

    get service() {
        if (!this._service) {
            this._service = new AppointmentService(this.props)
        }
        return this._service
    }

    open = agendamento => {
        this.props.setAppointment(agendamento)
        this.props.history.push('/agendamento')
    }

    cancel = agendamento => {
        this.props.setAppointment(agendamento)
        this.props.history.push('/cancelar-agendamento')
    }

    renderTable = appts => this.props.isMobile ? this.renderSmall(appts) : this.renderLarge(appts)

    renderSmall = appts => (
        <>
            <br />
            <Grid container className="oddRow">
                <Grid item xs={12}>
                    Agendamentos
                </Grid>
            </Grid>
            {appts.map((e, index) => (
                <Grid key={index} container className={index % 2 === 0 ? 'evenRow' : 'oddRow'}>
                    <Grid item xs={12}>
                        <AttendanceType topRight isOnline={e.isOnline}>
                            <div>Posto: <strong>Camim {e.filial}</strong></div>
                            <div>Data da consulta: <strong>{e.date}</strong></div>
                            <div>Horário: <strong>{e.time}</strong></div>
                            <div>Especialidade: <strong>{e.specialty}</strong></div>
                            <div>Médico: <strong>{e.doctor}</strong></div>
                        </AttendanceType>
                    </Grid>
                    <Grid container style={{ paddingTop: 18 }}>
                        <Grid item xs={6}>
                            <Button onClick={() => this.open(e)} style={{ width: 150 }} color='primary'>Visualizar</Button>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right'}}>
                            <Button onClick={() => this.cancel(e)}  style={{ width: 150 }} color='secondary'>Cancelar</Button>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </>
    )

    renderLarge = appts => (
        <Table 
            headers={[
                { title: 'Data da Consulta', render: item => (
                    <div style={{textAlign: 'center', fontWeight: 'bold' }}>
                        {item.date}
                    </div>
                )  },
                { title: 'Horário', field: 'time' },
                { title: 'Especialidade', render: item => (
                    <div style={{textAlign: 'center'}}>
                        {item.specialty}
                        <br />
                        {item.filial}
                    </div>
                ) },
                { title: 'Médico', render: item => (
                    <>
                        <div style={{ marginBottom: 6 }}>{item.doctor}</div>
                        <AttendanceType isOnline={item.isOnline} />
                    </>
                ) },
                { title: '', className: 'command2x', render: item => (
                    <>
                        <Button onClick={() => this.open(item)} fullWidth color='primary' style={{ marginBottom: 8 }}>Visualizar</Button>
                        <Button onClick={() => this.cancel(item)}  fullWidth color='secondary'>Cancelar</Button>
                    </>
                ) },
            ]}
            rows={appts}
        />
    )

    componentDidMount = () => {
        if (!Config.isProduction) {
            this.service.extraData = {
                refDate: '20201020'
            }
        }
        this.subscriber = this.service.appointments(filial => this.setState({ filial })).subscribe(
            items => this.setState({ appointments: [ ...this.state.appointments, ...items ]}), 
            error => {},
            () => {}
        )
    }

    componentWillUnmount = () => !!this.subscriber && this.subscriber.dispose()

    render = () => {
        const { filial, appointments } = this.state
        const { isMobile } = this.props
        const appts = appointments.sort((bf, af) => bf.sqlDate < af.sqlDate ? -1 : 1)
        return (
            <Page title='Consultas Agendadas'>
                {!!filial && <Alert requesting success>
                    {isMobile ? 'Verificando em' : 'Obtendo informações de'} {filial.name}...
                </Alert>}
                {(appts.length > 0) && this.renderTable(appts)}
                {!filial && (appts.length === 0) && <Alert center>
                    Não há agendamentos
                </Alert>}
            </Page>
        )
    }
}

export default withAuth(Appointment)

import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { blueGrey, red, grey } from '@material-ui/core/colors'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: blueGrey[500]
        },
        default: {
            main: grey[200]
        },
        secondary: {
            main: red[800]
        },
    },
})


export default ({ children }) => (
    <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
)
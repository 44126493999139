import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { grey, blueGrey } from '@material-ui/core/colors'

const position = {
    position: 'relative',
    top: 2,
    marginRight: 8,
}

const styles = ({
    default: {
        ...position,  
        color: grey[300],
    },
    primary: {
        ...position,
        color: blueGrey[500]
    },
    success: {
      ...position,  
      color: '#267A34',
    },
    danger: {
      ...position,  
      color: '#7c1806',
    },
    warning: {
      ...position,  
      color: '#8A6D3B',
    },
    ghost: {
        ...position,  
        color: blueGrey[500],
    }
})
  

const Alert = ({ center = false, ghost = false, primary = false, danger = false, success = false, warning = false, requesting = false, children, classes, style, ...props }) => {
    const type = ghost ? 'ghost' : primary ? 'primary' : success ? 'success' : danger ? 'danger' : warning ? 'warning' : ''
    const circularColor = ghost ? styles.ghost : success ? styles.success : danger ? styles.danger : warning ? styles.warning : styles.default

    return (
        <div className={`alert alert-${type} ${center ? 'alert-center' : ''}`} {...props}>
            {requesting && <CircularProgress style={circularColor} size={16} color='primary' />} {children}
        </div>
    )
}

export default withStyles(styles)(Alert)
import React from 'react'
import { Page, Input, Select, Alert, Button, Card } from '../../components' 
import { Link } from 'react-router-dom'
import { utils, withCamim } from '../../utils'
import { Grid } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import LoginService from '../../services/LoginService'

class Forgot extends React.Component {
    state = { 
        filial: null
    }

    constructor(props) {
        super(props)
        props.setForm('recover', {})
    }

    get loginService() {
        if (!this._loginService) {
            this._loginService = new LoginService(this.props)
        }
        return this._loginService
    }

    submit = async evt => {
        evt.preventDefault()
        try {
            const { filials = [], getForm } = this.props
            let { matricula, email } = getForm('recover')
            const { filial: code } = this.state
            this.setState({ isRequesting: true, success: false, error: null })
            const filial = filials.find(e => e.code === code)

            if (matricula.replace(/[0-9]/g, '') === '') {
                matricula = `${matricula}${code}`
            }
    
            await this.loginService.recover(filial, { matricula, email })
    
            this.setState({ isRequesting: false, success: true })
    
        } catch (error) {
            console.log('error', error)
            this.setState({ error: error.error })
            this.setState({ isRequesting: false })
        }   
    }

    pickFilial = filial => this.setState({ filial })

    canRecover = () => {
        if (!this.state.filial) {
            return false
        }
        const { matricula, email } = this.props.getForm('recover')
        if (!matricula || matricula.trim() === '') {
            return false
        }
        else if (!utils.isEmailValid(email)) {
            return false
        }
        return true
    }
    
    render = () => {
        const { filial = '', isRequesting = false, error, success = false } = this.state
        const { filials = [] } = this.props
        const items = filials.map(e => ({ value: e.code, title: e.name }))
        return (
            <Page title='Recuperar Senha' customBreadcrumb={{ 
                icon: <LockIcon />,
                title: 'Login'
            }}>
                <Alert ghost>
                    <div>A senha padrão, caso não tenha alterado, são os 6 dígitos iniciais do seu CPF.</div>
                    <div>Exemplo: CPF: 110.220.330-44 a senha será 110220.</div>
                </Alert>

                <Card>
                    <div>
                        Para recuperar a sua senha, escolha o posto e entre com o email cadastrado na Camim.
                    </div>

                    <p>
                        Não possui email cadastrado? <Link className="sectionColor" to="/resetar-senha">Clique aqui e CRIE UMA NOVA SENHA!</Link>
                    </p>

                    <form autoComplete="off" onSubmit={this.submit}>

                        <Select
                            placeholder='Escolha o posto'
                            value={filial}
                            fullWidth
                            onChangeValue={this.pickFilial}
                            items={items}
                            // cols={4}
                        />

                        <Input
                            title='Matrícula'
                            placeholder='Informe a matrícula'
                            field='recover.matricula'
                            fullWidth
                            cols={4}
                            onFormatValue={utils.allowMatricula(filials)}
                        />

                        <Input
                            title='Email'
                            type='email'
                            placeholder='Informe o email'
                            field='recover.email'
                            fullWidth
                            cols={12}
                        />
                        
                        <Grid container>
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <Button type="submit" tabIndex="-1" isRequesting={isRequesting} disabled={isRequesting || !this.canRecover()} color={this.canRecover() ? 'primary' : 'secondary'}>
                                    {isRequesting ? 'Recuperando...' : 'Recuperar Senha'}
                                </Button>
                            </Grid>
                        </Grid>

                        {success && <Alert success>
                            <strong>Sucesso:</strong> A senha foi encaminhada ao seu email
                        </Alert>}
                        {!!error && <Alert danger>
                            <strong>Erro:</strong> {error}
                        </Alert>}

                    </form>
                </Card>
            </Page>
        )
    }
}

export default withCamim(Forgot)

    
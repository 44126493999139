import React from 'react'
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import FieldMask from './FieldMask'
import { connect } from 'formik'

const Field = ({
    name,
    type,
    formik,
    className,
    ...props
}) => {


    const { handleChange, handleBlur, values, errors, setFieldValue } = formik

    let component = null

    switch(type) {
        case 'phone':
        case 'zipCode':
            component = (
                <FieldMask
                    type={type}
                    name={name}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors[name]}
                    {...props}
                />
            )
            break
        case 'checkbox':
            component = (
                <FormControlLabel
                    className={className}
                    label={props.label}
                    control={
                        <Checkbox
                            color='primary'
                            checked={values[name] || false}
                            onChange={({ target: { checked } }) => setFieldValue(name, checked || false)}
                        />
                    }
                />
            )
            break
        default:
            component = (
                <TextField 
                    fullWidth
                    helperText={errors[name]}
                    {...props}
                />
            )
            break
    }
    
    return component
}

export default connect(Field)

import React from 'react'
import { Button as MatButton, CircularProgress } from '@material-ui/core'

const Button = ({ children, disabled, color = 'primary', variant = 'contained', isRequesting = false, ...rest }) => (
    <MatButton {...rest} variant={variant} color={color} disabled={disabled || isRequesting}>
        {isRequesting && <CircularProgress size={16} style={{ marginRight: 8 }} />} {children}
    </MatButton>
)

export default Button

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'


const color = {
  default: '#666',
  success: '#267A34',
  danger: '#7c1806',
  warning: '#8A6D3B',
  dark: '#000',
}

const titleStyle = {
  letterSpacing: '-1px',
  marginBottom: 8,
}

const underlineStyle = borderBottomColor => ({
  borderBottom: '2px solid',
  borderBottomColor,
})

const styles = theme => ({
    default: {
      ...titleStyle,
      color: color.default
    },
    success: {
      ...titleStyle,
      color: color.success
    },
    danger: {
      ...titleStyle,
      color: color.danger
    },
    warning: {
      ...titleStyle,
      color: color.warning
    },
    dark: {
      ...titleStyle,
      color: color.dark
    },
})
  

const Title = ({ variant = 'h6', center = false, underline = false, dark = false, danger = false, success = false, warning = false, children, classes, style, ...props }) => {
  const kindClass = dark ? 'dark' : danger ? 'danger' : success ? 'success' : warning ? 'warning' : 'default'
  return (
      <div style={{...(underline ? underlineStyle(color[kindClass]) : {  marginBottom: 8 }), ...style}}>
        <Typography 
          variant={variant} 
          className={classes[kindClass]}
          style={{ textAlign: center ? 'center' : 'left'}}
        >
            {children}
        </Typography>
      </div>
  )
}

export default withStyles(styles)(Title)
import React from 'react'
import { Grid } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { Page, Title, Alert, Select, Input, Button } from '../../components' 
import { utils, withCamim } from '../../utils'
import LoginService from '../../services/LoginService'

class ResetPassword extends React.Component {
    state = { }

    constructor(props) {
        super(props)
        props.setForm('reset', {})
    }

    get loginService() {
        if (!this._loginService) {
            this._loginService = new LoginService(this.props)
        }
        return this._loginService
    }

    pick = field => value => this.setState({ [field]: value })

    canRecover = () => {
        const { filial, tipoMatricula, isRequesting = false } = this.state
        const { matricula, name, bornDate, email, cpf, lastMensalidade, venctDay } = this.props.getForm('reset')
        
        const answer = { disabled: true, title: 'Iniciando o processo...' }
        if (isRequesting) {
            return answer
        }
        else if (!filial) {
            return {...answer, title: 'Escolha o posto' }
        }
        else if (utils.isEmpty(matricula)) {
            return {...answer, title: 'Informe a matrícula' }
        }
        else if (utils.isEmpty(name)) {
            return {...answer, title: 'Informe o nome' }
        }
        else if (utils.isEmpty(bornDate)) {
            return {...answer, title: 'Informe a data de nascimento' }
        }
        else if (!utils.isEmailValid(email)) {
            return {...answer, title: 'Informe o email corretamente' }
        }
        else if (utils.isEmpty(tipoMatricula)) {
            return {...answer, title: 'Informe o tipo de matrícula' }
        }
        else if (utils.isEmpty(cpf)) {
            return {...answer, title: 'Informe o CPF' }
        }
        else if (utils.isEmpty(lastMensalidade)) {
            return {...answer, title: 'Informe o valor da última mensalidade' }
        }
        else if (utils.isEmpty(venctDay)) {
            return {...answer, title: 'Informe o dia de vencimento' }
        }

        return {
            disabled: false,
            title: 'Iniciar o processo de recuperação de senha'
        }
    }

    submit = async evt => {
        evt.preventDefault()
        try {
            const { filials = [], getForm } = this.props
            const json = getForm('reset')
            const { filial: code, tipoMatricula } = this.state
            this.setState({ isRequesting: true, success: false, error: null })
            const filial = filials.find(e => e.code === code)

            json.name = json.name.toUpperCase()

            if (json.matricula.replace(/[0-9]/g, '') === '') {
                json.matricula = `${json.matricula}${code}`
            }
    
            await this.loginService.recreatePassword(filial, {...json, filial: code, tipoMatricula })
    
            this.setState({ isRequesting: false, success: true })
    
        } catch (error) {
            console.log('error', error)
            this.setState({ error: error.error || 'Erro no envio do email. Tente novamente.' })
            this.setState({ isRequesting: false })
        }   
    }
    
    render = () => {
        const { tipoMatricula, filial, isRequesting, success = false, error } = this.state
        const { filials = [] } = this.props
        const items = filials.map(e => ({ value: e.code, title: e.name }))

        const canRecover = this.canRecover()

        return (
            <Page 
                card
                title='Recuperação de Senha' 
                customBreadcrumb={{ 
                    icon: <LockIcon />,
                    title: 'Login'
                }}
                breads={[
                    { title: 'Esqueceu a senha', link: '/recuperar-senha' }
                ]}
            >
                <Title underline success>Entre com as informações abaixo para iniciar o processo de recriação de senha</Title>

                <form autoComplete="off" onSubmit={this.submit}>
                    <Select
                        placeholder='Escolha o posto'
                        value={filial}
                        fullWidth
                        onChangeValue={this.pick('filial')}
                        items={items}
                    />

                    <Input
                        title='Matrícula'
                        placeholder='Informe a matrícula'
                        field='reset.matricula'
                        fullWidth
                        cols={12}
                        onFormatValue={utils.allowMatricula(filials)}
                    />

                    <Input
                        title='Nome'
                        placeholder='Informe o nome completo'
                        field='reset.name'
                        fullWidth
                        cols={12}
                    />

                    <Input
                        title='Data de nascimento'
                        placeholder='Informe a data de nascimento'
                        field='reset.bornDate'
                        fullWidth
                        cols={3}
                    />

                    <Input
                        title='Email'
                        type='email'
                        placeholder='Informe o email'
                        field='reset.email'
                        fullWidth
                        cols={12}
                    />

                    <Select
                        placeholder='Tipo de Matrícula'
                        value={tipoMatricula}
                        fullWidth
                        onChangeValue={this.pick('tipoMatricula')}
                        items={[
                            { title: 'Titular', value: 'T' },
                            { title: 'Dependente', value: 'D' },
                        ]}
                    />

                    <Input
                        title='CPF'
                        placeholder='Informe o CPF'
                        field='reset.cpf'
                        fullWidth
                        cols={4}
                    />

                    <Input
                        title='Valor da última mensalidade'
                        placeholder='Informe a última mensalidade'
                        field='reset.lastMensalidade'
                        fullWidth
                        cols={4}
                    />

                    <Input
                        title='Dia do vencimento'
                        placeholder='Informe o dia do vencimento'
                        field='reset.venctDay'
                        fullWidth
                        cols={4}
                    />

                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button type="submit" tabIndex="-1" isRequesting={isRequesting} disabled={isRequesting || canRecover.disabled}>
                                {canRecover.title}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                {success && <Alert success>
                    <strong>Sucesso:</strong> A senha foi encaminhada ao seu email
                </Alert>}
                {!!error && <Alert danger>
                    <strong>Erro:</strong> {error}
                </Alert>}


            </Page>
        )
    }
}

export default withCamim(ResetPassword)

    
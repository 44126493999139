import React from 'react'
import { Grid } from '@material-ui/core'
import { withCamim } from '../../../utils'
import '../../../assets/styles/css/pick_time.css'

const AppointmentView = ({ 
    user: loggedUser, 
    fullName,
    appointmentView: appointment, 
    filial,
    before, 
    middle 
}) => {
    
    const user = loggedUser || { matricula: appointment.matricula, codeFilial: appointment.filialCode, nome: appointment.name }
    
    return (
        <>
            {before}
            <Grid container>
                <Grid item xs={12}>
                    <div className="offsetBottom"><strong>Posto de Atendimento:</strong> { appointment.filial }</div>
                    {filial?.mustShowAddress && (
                        <>
                            <div className="offsetBottom"><strong>Endereço:</strong> { filial.address } - {filial.neighborhood}</div>
                            {filial.phone && <div className="offsetBottom"><strong>Telefone:</strong> { filial.phone }</div>}
                            <br />
                        </>
                    )}
                    <div className="offsetBottom"><strong>Médico:</strong> { appointment.doctor || appointment.nomeMedico }</div>
                    <div className="offsetBottom"><strong>Especialidade:</strong> { appointment.specialty || appointment.especialidade }</div>
                    <div className="offsetBottom"><strong>Talão:</strong> { appointment.idLancamento || appointment.codigo }</div>
                    <div className="offsetBottom"><strong>Data de Atendimento:</strong> { appointment.date || appointment.brDate }</div>
                </Grid>
            </Grid>

            {middle}

            <Grid container>
                <Grid item xs={12}>
                    <div className="offsetBottom"><strong>Hora aproximada do atendimento:</strong> {appointment.time || appointment.brTime}h</div>
                    <div className="offsetBottom"><strong>Matrícula:</strong> { user.matricula }{user.codeFilial}</div>
                    <div className="offsetBottom"><strong>Paciente:</strong> { fullName }</div>
                    <div className="offsetBottom"><strong>Ordem na fila:</strong> { appointment.consulta }</div>
                </Grid>
            </Grid>
        </>
    )
}

export default withCamim(AppointmentView)
import React from 'react'
import { Page, Title, FormCommand, AttendanceType } from '~/components' 
import { withAuth } from '~/utils/withAuth'
import AppointmentView from './Other/AppointmentView'
import '~/assets/styles/css/appointment.css'

class ShowAppointment extends React.Component {
    state = { appointment: null }

    constructor(props) {
        super(props)
        console.log(props)
    }

    cancel = () => {
        const { appointment } = this.state
        this.props.setAppointment(appointment)
        this.props.history.push('/cancelar-agendamento')
    }

    componentDidMount = () => {
        const { appointment } = this.props
        if (!appointment) {
            return this.props.history.replace('/consultas-agendadas')
        }
        this.props.removeAppointment(appointment)
        this.setState({ appointment })
    }
    
    render = () => {
        const { appointment } = this.state
        if (!appointment) {
            return null
        }

        const { translate, isMobile, filials = [] } = this.props
        const filial = filials.find(x => x.code === appointment.filialCode)

        return (
            <Page card title='Agendamento' breads={[
                { title: 'Consultas Agendadas', link: '/consultas-agendadas' }
            ]}>
                <Title success underline>Agendamento</Title>

                {!!appointment && (
                    <AttendanceType topRight isOnline={appointment.isOnline}>
                        <AppointmentView
                            appointmentView={appointment}
                            filial={filial}
                            before={(
                                <div className={isMobile ? 'alertStrongSmall' : 'alertStrong'}>
                                    {translate('presenca_terminal_atendimento', 'É NECESSÁRIO CONFIRMAR SUA PRESENÇA NO DIA DA CONSULTA NOS TERMINAIS DE AUTOATENDIMENTO OU NAS RECEPÇÕES')}
                                </div>
                            )}
                            middle={(
                                <div className={isMobile ? 'middleAlertStrongSmall' : "middleAlertStrong"}>
                                    { translate('patient_15_minutes_late', 'O PACIENTE QUE CHEGAR COM MAIS DE 15 MINUTOS DE ATRASO TERÁ SUA CONSULTA REMARCADA.') }
                                </div>
                            )}
                        />

                        <br />
                        <FormCommand
                            hasBack
                            to='/consultas-agendadas'
                            leftTitle='Voltar'
                            actionTitle='Cancelar'
                            onActionButtonClick={this.cancel}
                            actionButtonColor='secondary'
                        />
                        
                    </AttendanceType>
                )}

            </Page>
        )
    }
}

export default withAuth(ShowAppointment)

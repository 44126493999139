import React from 'react'
import { Page, Select, FormCommand, Table } from '../../components' 
import { Grid, InputAdornment } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import CalendarIcon from '@material-ui/icons/CalendarToday'

class ChangeNetwork extends React.Component {
    state = { 
        initialDate: moment(),
        finalDate: moment()
    }

    items = [{ value: 1, title: 'Todos' }]
    minDate = moment().add(-1, 'd')
    maxDate = moment()

    submit = evt => {
        evt.preventDefault()
        const { initialDate, finalDate } = this.state
        if (finalDate.isBefore(initialDate)) {
            return alert('A data inicial deverá ser menor ou igual a final')
        }
        this.setState({ isSubmitted: true })
    }
    
    render = () => {
        const { initialDate, finalDate, isSubmitted = false } = this.state
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <form onSubmit={this.submit}>
                    <Page card title='Mudança de Rede' cardTitle='Mudança de Rede' hideBreadcrumb>
                        <Grid container spacing={4} style={{ marginTop: 12 }}>
                            <Grid item xs={3}>
                                <Select
                                    placeholder='Planos'
                                    value={1}
                                    items={this.items}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    placeholder='Tipo de estabelecimento'
                                    value={1}
                                    items={this.items}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DatePicker
                                    fullWidth
                                    className='noHelper'
                                    label="Período inicial"
                                    placeholder="DD/MM/YYYY"
                                    value={initialDate}
                                    minDate={this.minDate}
                                    maxDate={this.maxDate}
                                    onChange={initialDate => this.setState({ initialDate })}
                                    format="DD/MM/YYYY"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={style.iconPosition}>
                                                <CalendarIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DatePicker
                                    fullWidth
                                    className='noHelper'
                                    label="Período final"
                                    placeholder="DD/MM/YYYY"
                                    value={finalDate}
                                    minDate={this.minDate}
                                    maxDate={this.maxDate}
                                    onChange={finalDate => this.setState({ finalDate })}
                                    format="DD/MM/YYYY"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={style.iconPosition}>
                                                <CalendarIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <FormCommand 
                            actionTitle='Consultar'
                        />
                        {isSubmitted && (
                            <>
                                <br />
                                <Table
                                    headers={[
                                        { title: 'Prestador descredenciado' },
                                        { title: 'Prestador substituto' }
                                    ]}
                                    rows={[]}
                                />
                                <div style={style.center}>
                                    Nenhuma substituição de prestadores realizada.
                                </div>
                            </>
                        )}
                    </Page>
                </form>
            </MuiPickersUtilsProvider>
        )
    }
}

const style = {
    iconPosition: {
        position: 'relative', 
        top: -4,
    },
    center: {
        textAlign: 'center',
        marginTop: 24,
    }
}

export default ChangeNetwork

    
// import camimFirebase from '../../services/CamimFirebase'
import { api } from '../../services/ApiService'

const MemoryAction = {
    setPageTitle: 'setPageTitle', 
    setSpecialties: 'setSpecialties', 
    storeFilials: 'storeFilials',
    loadingFilials: 'loadingFilials',
    setLoadingFilials: 'setLoadingFilials',
    loadingFilialsAndSpecialties: 'loadingFilialsAndSpecialties',
    storeFilialsAndSpecialties: 'storeFilialsAndSpecialties',
    setObject: 'setObject',
    removeObject: 'removeObject',
}

const initialState = {
    pageTitle: '',
    config: {}
}

const loadingFilials = async next => {
    next({ type: MemoryAction.setLoadingFilials })
    try {
        next({ type: MemoryAction.storeFilials, payload: await api.get('filial?status=2')})
    } catch (error) {
        console.log('erro de leitura', error)
    }
}

const loadingFilialsAndSpecialties = async next => {
    next({ type: MemoryAction.setLoadingFilials })
    try {
        next({ type: MemoryAction.storeFilialsAndSpecialties, payload: await api.get('filial-specialty')})
    } catch (error) {
        console.log('erro de leitura', error)
    }
}

const MemoryMiddleware = state => next => action => {
    switch(action.type) {
        case MemoryAction.loadingFilials:
            return loadingFilials(next)
        case MemoryAction.loadingFilialsAndSpecialties:
            return loadingFilialsAndSpecialties(next)
        default:
            break
        }
        next(action)
}

const MemoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case MemoryAction.setPageTitle:
            return { ...state, pageTitle: action.payload }
        case MemoryAction.setSpecialties:
            return { ...state, specialties: action.payload }
        case MemoryAction.storeFilials:
            return { 
                ...state, 
                isLoadingFilials: false, 
                filials: action.payload.sort((bf, af) => bf.name < af.name ? -1 : 1), 
            }
        case MemoryAction.storeFilialsAndSpecialties:
            return { 
                ...state, 
                isLoadingFilials: false, 
                filials: action.payload.filials.sort((bf, af) => bf.name < af.name ? -1 : 1), 
                specialties: action.payload.specialties.map(e => ({ title: e.especialidade, value: e.keyEspecialidade, hash: e.keyEspecialidade.replace(' ', '_'), canSchedule: e.canSchedule === 1 })),
            }
        case MemoryAction.loadingFilials:
            return { ...state, isLoadingFilials: false }
        case MemoryAction.setLoadingFilials:
            return { ...state, isLoadingFilials: true }
        case MemoryAction.setObject:
            return { ...state, ...action.payload }
        case MemoryAction.removeObject:
            return { ...state, [action.payload]: null }
    
        default:
            return state
    }
}

const memoryMapStateToProps = state => {
    const memory = state.memory || {}
    const { width, specialties, filials, isLoadingFilials = false, appointment, dictionaryToTranslate, userExam } = memory
    const isMobile = !!width && width <= 768
    const isRegularScreen = !isMobile && !!width && width < 1272

    return {
        ...memory,
        isMobile,
        isRegularScreen,
        pageTitle: memory.pageTitle,
        specialties,
        filials,
        isLoadingFilials,
        appointment,
        userExam,
        mustLoginExam: !userExam,
        dictionaryToTranslate: dictionaryToTranslate || { mustDownload: true, downloading: false },
        translate: (key, defaultValue) => {
            if (!dictionaryToTranslate) {
                return defaultValue
            }
            return (dictionaryToTranslate[key] || { value: defaultValue }).value
        }
    }
}

const memoryMapDispatchToProps = dispatch => ({
    dispatch,
    setPageTitle: title => dispatch({ type: MemoryAction.setPageTitle, payload: title }),
    setSpecialties: specialties => dispatch({ type: MemoryAction.setSpecialties, payload: specialties }),
    setAppointment: value => dispatch({ type: MemoryAction.setObject, payload: { appointment: value }}),
    removeAppointment: () => dispatch({ type: MemoryAction.removeObject, payload: 'appointment' }),
    storeFilials: filials => dispatch({ type: MemoryAction.storeFilials, payload: filials }),
    loadingFilials: () => dispatch({ type: MemoryAction.loadingFilials }),
    loadingFilialsAndSpecialties: () => dispatch({ type: MemoryAction.loadingFilialsAndSpecialties }),
    setTranslate: dictionaryToTranslate => dispatch({ type: MemoryAction.setObject, payload: { dictionaryToTranslate } }),
    loginExam: userExam => dispatch({ type: MemoryAction.setObject, payload: { userExam } }),
    setWindowSize: width => dispatch({ type: MemoryAction.setObject, payload: { width } }),
    setSchedulePsychology: schedulePsychology => dispatch({ type: MemoryAction.setObject, payload: { schedulePsychology } }),

    setConfig: config => dispatch({ type: MemoryAction.setObject, payload: { config }}),
})

export { MemoryReducer, MemoryMiddleware, memoryMapStateToProps, memoryMapDispatchToProps }
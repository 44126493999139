import fb from './CamimFirebase'
import moment from 'moment'
import { utils } from '../utils'

export default class {

    constructor(props) {
        this.props = props
    }

    get user() {
        return this.props.user
    }

    loginExam = (filial, credentials) => fb.request('loginExam', filial, credentials).then(data => {
        this.props.loginExam({...data.result, codeFilial: filial.code })
        return data
    })

    login = (matricula, password, filial) => fb
        .request('login', filial, { 'matricula': matricula, 'password': password })
        .then(rs => {
            const user = rs.result

            const data = user.dependente || user

            user.crypt = utils.createCrypto(password)
            user.cryptBirthday = utils.createCryptoFromDate(data.dataNascimento)
            user.cryptCPF = utils.createCryptoFromDate(data.cpf || '')
            const parts = data.nome.split(' ')
            const lastIndex = parts.length - 1
            user.name = `${parts[0]} ${parts[lastIndex]}` 
            user.lastDate = moment().format()
            user.codeFilial = filial.code
            user.filial = filial
            this.props.login && this.props.login(user)
            fb.setUser(user)
            return user
        })
        .catch(err => {
            const textError = err.error || 'Matrícula e/ou senha incorreto(s)'
            const parts = textError.split('|')
            const msgError = parts.length === 2 ? parts[1] : textError
            return Promise.reject(msgError)
        })
    
    
    recover = (filial, dictionary) => fb.request('recoverPassword', filial, { ...dictionary, filial: filial.code })
    recreatePassword = (filial, dictionary) => fb.request('recreatePassword', filial, dictionary)
        
    userInfo = () => fb.request('userInfo', this.user.filial, {})
    messages = () => fb.request('userMessages', this.user.filial)
    markMessageAsRead = id => fb.request('markUserMessageAsRead', this.user.filial, { id: id })
    deleteMessage = id => fb.request('deleteUserMessage', this.user.filial, { id: id })
}
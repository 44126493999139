import React from 'react'
import { FormConnectRedux } from '../../store/ducks/FormDuck'
import TextField from '@material-ui/core/TextField'
import { Grid }  from '@material-ui/core'

const Input = ({ 
    title, 
    placeholder,
    type = 'text', 
    field, 
    setFormValue, 
    onChangeText, 
    setForm,
    dispatch,
    clearForm,
    getFormValue, 
    getFormValues,
    getForm,
    getValue,
    onFormatValue,
    cols,
    ...rest 
}) => {

    const onChange = evt => {
        const { value = '' } = evt.target
        const vlToStore = !!onFormatValue ? onFormatValue(value) : value
        setFormValue(field, vlToStore)
        !!onChangeText && onChangeText(vlToStore)
    }

    const textField = (
        <TextField 
            {...rest}
            label={title}
            placeholder={placeholder || title}
            type={type} 
            fullWidth
            value={getFormValue(field, '')}
            onChange={onChange}
        />
    )

    if (!cols) {
        return textField
    }

    return (
        <Grid container spacing={3} style={{ marginBottom: 8 }}>
            <Grid item xs={cols}>
                {textField}
            </Grid>
        </Grid>
    )
}

export default FormConnectRedux()(Input)

import styled from 'styled-components';

export const Relative = styled.div`
    position: relative;
`

export const Center = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
import moment from 'moment'
import Mask from '../constants/Mask'

const { SQL_MASK, BR_DATE, FIREBASE_KEY_DATETIME } = Mask

export class AppointmentAnswerModel {
    success = true
    codeFilial = null
    appointments = null
    attendance = null
}

export class AppointmentModel {
    date = null
    filialCode = null
    filialName = null
    dayOfWeek = null
    shiftTime = null
    doctor = null
    specialty = null
    doctorId = null
    specialtyId = null
    shiftTimeId = null
    dayOfWeekId = null
    sqlDate = null

    userData = null

    times = []

    constructor(filialName, json) {
        if (!json) {
            return
        }

        this.filialName = filialName
        this.date = json.date
        this.filialCode = json.filialCode
        this.dayOfWeek = json.dayOfWeek
        this.shiftTime = json.shiftTime
        this.doctor = json.doctor
        this.specialty = json.specialty
        this.doctorId = json.doctorId
        this.specialtyId = json.specialtyId
        this.shiftTimeId = json.shiftTimeId
        this.dayOfWeekId = json.dayOfWeekId
        this.sqlDate = json.sqlDate

        if (json.times == null) {
            return
        }

        let jsonTimes = json.times
        for (var key in jsonTimes) {
            if (jsonTimes.hasOwnProperty(key)) {
                let value = jsonTimes[key];
                this.times.push(new AppointmentTimeModel(value));
            }
        }        
    }


    get uniqueId() {
        return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36) + (this.doctorId.toString())
    }
}


export class AppointmentTimeModel {
    time = null
    number = null

    constructor(json) {
        if (!json) {
            return
        }

        this.time = json.time
        this.number = json.number
    }
}

export class AppointmentFilial {
    success = true
    error = null
    lancamentoId = 0
    codigo = 0
    especialidade = ""
    filial = ""
    filialCode = ""
    isConsulting = false
    nomeMedico = ""
    posto = ""
    consulta = 0
    isConfirmed = false
    sqlDate = ""
    brDate = ""
    brTime = ""
    scheduleDate = null
    intScheduleDate = 0

    constructor(json) {
        this.parse(json)
    }

    parse(json) {
        if (!json) {
            return
        }

        this.lancamentoId = json.lancamentoId || json.idLancamento
        this.codigo = json.codigo || json.code
        this.especialidade = json.especialidade || json.specialty
        this.filial = json.filial
        this.filialCode = json.filialCode
        this.isConsulting = json.isConsulting || json.isConsulting === 1
        this.nomeMedico =  json.nomeMedico || json.doctor
        this.posto =  json.posto || json.filialMatricula
        this.consulta = json.consulta
        this.sqlDate = json.sqlDate
        this.brDate = moment(json.sqlDate).format(BR_DATE)
        this.brTime = json.time
        this.isConfirmed = json.isConfirmed

        const time =  json.horaPrevistaConsulta || json.time
        if (!time && time.trim().length === 0) {
            return
        }

        const dt = this.sqlDate + ' ' + time
        this.scheduleDate = moment(dt, SQL_MASK)
        this.intScheduleDate = parseInt(this.scheduleDate.format(FIREBASE_KEY_DATETIME))
    }

    // date() {
    //     return this.scheduleDate.format(BR_DATE)
    // }

    // time() {
    //     return this.scheduleDate.forma)
    // }
}
import React from 'react'
import { Grid } from '@material-ui/core'
import { Page } from '../../components'
import { withAuth } from '../../utils/withAuth'
import moment from 'moment'
import { utils } from '../../utils/Utils'
import Mask from '../../constants/Mask'
import '../../assets/styles/css/account.css'
import '../../assets/styles/css/table.css'

class Account extends React.Component {
    renderDate = date => moment(date, Mask.SQL_FULL_DATETIME_MASK).format(Mask.BR_DATE)

    renderRowSmall = (firstColumn, valueFirstColumn, isGray = true, isExpanded = false) => (
        <Grid container className={isGray ? 'oddRow' : 'evenRow'}>
            <Grid item xs={isExpanded ? 12 : 4}>
                {firstColumn}
            </Grid>
            <Grid item xs={isExpanded ? 12 : 8} style={{ textAlign: 'right' }}>
                <strong>{valueFirstColumn}</strong>
            </Grid>
        </Grid>
    )

    renderRow = (firstColumn, valueFirstColumn, secondColumn, valueSecondColumn) => (
        <tr>
            <td className="bold">{firstColumn}</td>
            <td>{valueFirstColumn}</td>
            <td className="bold first">{secondColumn}</td>
            <td className="first">{valueSecondColumn}</td>
        </tr>
    )

    renderSmall = () => {
        const { user } = this.props
        return (
            <Page card title="Dados Pessoais" cardTitle="Dados do Titular">
                <br />
                {this.renderRowSmall('Nome', user.nome, true, true)}
                {this.renderRowSmall('Matrícula', user.matricula, false)}
                {this.renderRowSmall('Data de Admissão', this.renderDate(user.dataAdmissao))}
                {this.renderRowSmall('Email', user.email, false, true)}
                {this.renderRowSmall('CPF', user.cpf)}
                {this.renderRowSmall('Endereço', user.endereco, false, true)}
                {this.renderRowSmall('Bairro', user.bairro)}
                {this.renderRowSmall('cidade', user.cidade, false)}
                {this.renderRowSmall('CEP', user.cep)}
                {this.renderRowSmall('Data Nasc.', this.renderDate(user.dataNascimento), false)}
                {this.renderRowSmall('Profissão', user.profissao || user.ocupacao)}
                {this.renderRowSmall('Tel. Residencial', user.telefoneResidencial, false, true)}
                {this.renderRowSmall('Tel. Celular', user.telefoneCelular)}
                {this.renderRowSmall('Plano', user.plan, false)}
                {this.renderRowSmall('Situação', user.situation)}
                {this.renderRowSmall('Valor da mensalidade', utils.formatPrice(user.lastPayment), false, true)}
            </Page>
        )
    }

    renderLarge = () => {
        const { user, fullName } = this.props
        return (
            <Page card title="Dados Pessoais" cardTitle="Dados do Titular">
                <table className="tableDataInfo">
                    <tbody>
                        <tr>
                            <td className="bold first">Nome</td>
                            <td colSpan={3} className="left">
                                {fullName}
                            </td>
                        </tr>

                        {this.renderRow('Matrícula', user.matricula, 'Data de Admissão', this.renderDate(user.dataAdmissao))}

                        {this.renderRow('E-Mail', user.email, 'CPF', user.cpf)}

                        {this.renderRow('Endereço', `${user.endereco}, ${user.numero}`, 'Bairro', user.bairro)}

                        {this.renderRow('Cidade', user.cidade, 'CEP', user.cep)}

                        {this.renderRow('Data de Nascimento', this.renderDate(user.dataNascimento), 'Profissão', user.profissao || user.ocupacao)}

                        {this.renderRow('Telefone Residencial', user.telefoneResidencial, 'Telefone Celular', user.telefoneCelular)}

                        {this.renderRow('Plano', user.plan, 'Situação', user.situation)}

                        {!!user.lastPayment && (
                            <tr>
                                <td className="bold first">Valor da mensalidade</td>

                                <td colSpan={3} className="left">
                                    {utils.formatPrice(user.lastPayment)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Page>
        )
    }

    render = () => (this.props.isMobile ? this.renderSmall() : this.renderLarge())
}

export default withAuth(Account)

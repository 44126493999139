import Dashboard from '../modules/Dashboard/Dashboard'
import Appointment from '../modules/Appointment/Appointment'
import OnlyOnApp from '../modules/OnlyOnApp'
// import MakeAppointment from '../modules/Appointment/MakeAppointment'
import Exam from '../modules/Exam/Exam'
import Finance from '../modules/Finance/Finance'
import InformEmail from '../modules/InformEmail/InformEmail'
import Account from '../modules/Account/Account'
import ChangePassword from '../modules/ChangePassword/ChangePassword'
// import Portabilidade from '../modules/Portabilidade/Portabilidade'
import TimeAttendanceAvailable from '../modules/TimeAttendance/TimeAttendanceAvailable'
import Forgot from '../modules/Forgot/Forgot'
import ResetPassword from '../modules/ResetPassword/ResetPassword'
import ShowAppointment from '../modules/Appointment/ShowAppointment'
import CancelAppointment from '../modules/Appointment/CancelAppointment'
import CancelLinkAppointment from '../modules/Appointment/CancelLinkAppointment'
import ChangeNetwork from '../modules/ChangeNetwork/ChangeNetwork'
// import AgendamentoPsicologico from '../modules/AgendamentoPsicologico/AgendamentoPsicologico'
// import ConfirmarAgendamentoPsicologico from '../modules/AgendamentoPsicologico/ConfirmarAgendamentoPsicologico'

export default [
    {
        path: '/',
        component: Dashboard,
        hidden: true,
    },
    {
        path: '/horarios-atendimento/:hash',
        component: TimeAttendanceAvailable,
        hidden: true,
    },
    {
        path: '/mudanca-rede',
        component: ChangeNetwork,
        hidden: true,
    },
    {
        title: 'Agendamento Psicólogo',
        path: '/agendamento-psicologo',
        icon: 'ico_psy.png',
        component: OnlyOnApp,
    },
    // {
    //     title: 'Agendamento Psicólogo',
    //     path: '/agendamento-psicologo',
    //     icon: 'ico_psy.png',
    //     component: AgendamentoPsicologico,
    // },
    // {
    //     title: 'Agendamento Hash',
    //     path: '/agendar/:hash',
    //     hidden: true,
    //     component: AgendamentoPsicologico,
    // },
    // {
    //     title: 'Confirmar Agendamento Psicólogo',
    //     path: '/confirmar-agendamento-psicologo',
    //     component: ConfirmarAgendamentoPsicologico,
    //     hidden: true,
    // },
    {
        title: 'Horários de Atendimento',
        href: 'https://www.camim.com.br/especialidades',
        icon: 'ic_horarios_atendimento.png',
    },
    {
        path: '/recuperar-senha',
        hidden: true,
        component: Forgot,
    },
    {
        path: '/resetar-senha',
        hidden: true,
        component: ResetPassword,
    },
    {
        title: 'Marcar Consulta',
        icon: 'ic_criar_agendamento.png',
        path: '/marcar-consulta',
        // component: MakeAppointment,
        component: OnlyOnApp,
    },
    {
        title: 'Consultas Agendadas',
        icon: 'ic_consultas_agendadas.png',
        path: '/consultas-agendadas',
        component: Appointment,
    },
    {
        path: '/agendamento',
        hidden: true,
        component: ShowAppointment,
    },
    {
        path: '/cancelar-agendamento',
        hidden: true,
        component: CancelAppointment,
    },
    {
        path: '/cancelar-agendamento/:hash',
        hidden: true,
        component: CancelLinkAppointment,
    },
    {
        title: 'Resultado de Exames',
        icon: 'ic_resultado_exames.png',
        path: '/resultado-exames',
        component: Exam,
    },
    {
        title: 'Relatório Financeiro',
        icon: 'ic_relatorio_financeiro.png',
        forbiddenDependent: true,
        forbiddenEnterprise: true,
        path: '/financeiro',
        component: Finance,
    },
    {
        title: 'Informar Email',
        icon: 'ic_informar_email.png',
        path: '/informar-email',
        component: InformEmail,
    },
    {
        title: 'Dados Pessoais',
        icon: 'ic_dados_pessoais.png',
        path: '/dados-pessoais',
        component: Account,
    },
    {
        title: 'Alterar Senha',
        icon: 'ic_alterar_senha.png',
        path: '/alterar-senha',
        component: ChangePassword,
    },
    // {
    //     path: '/portabilidade',
    //     hidden: true,
    //     component: Portabilidade,
    // },
]

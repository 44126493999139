import React from 'react'

export default () => {

    const date = new Date();
    const years = date.getFullYear() - 1975;
    
    return (
        <section id="text-2" className="fusion-footer-widget-column widget widget_text">
            <div className="textwidget">
                <p className="headerCSS_0">
                    <a href="https://wp-homolog.camim.com.br">
                        <img src="https://s3-sa-east-1.amazonaws.com/camim-site/wp-content/uploads/2019/04/22115326/logo-camim.png" alt="" />
                    </a>
                </p>
                <div className="fusion-sep-clear" />
                <div className="fusion-separator fusion-full-width-sep sep-none headerCSS_1" />
                <div className="headerCSS_2">
                    <p className="headerCSS_3">
                        Somos uma conceituada operadora de planos de saúde há {years} anos no mercado. Prezamos pela excelência nos atendimentos realizados em nossos postos conveniados que
                        possuem instalações modernas, laboratórios próprios e profissionais qualificados.
                    </p>
                </div>
                <div className="fusion-sep-clear" />
                <div className="fusion-separator fusion-full-width-sep sep-none headerCSS_4" />
                <div className="fusion-button-wrapper">
                    <a className="fusion-button button-flat fusion-button-pill button-large button-custom button-3" target="_self" href="https://wp-homolog.camim.com.br/a-empresa/">
                        <span className="fusion-button-text">Saiba Mais</span>
                    </a>
                </div>
            </div>
            <div className="headerCSS_5" />
        </section>
    )

}

import React from 'react'
import '../../assets/styles/css/footer.css'
import { Grid } from '@material-ui/core'
import Column1 from './Column1'
import Column2 from './Column2'
import Column3 from './Column3'
import Manufacturer from './Manufacturer'

const Footer = ({ isMobile }) => (
    <>
        <footer className="fusion-footer-widget-area fusion-widget-area">
            <div className="fusion-footer">
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Grid container spacing={5}>
                            <Grid item xs={isMobile ? 12 : 4}>
                                <Column1 />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 4}>
                                <Column2 />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 4}>
                                <Column3 />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </footer>
        <Manufacturer />
    </>
)

/*
const FooterOld = ({ isMobile }) => (
    <div className="fusion-footer">
        <footer className="fusion-footer-widget-area fusion-widget-area">
            <div className="fusion-row">
                <div className="fusion-columns fusion-columns-3 fusion-widget-area">
                    <div className="fusion-column col-lg-4 col-md-4 col-sm-4">
                        <section id="text-2" className="fusion-footer-widget-column widget widget_text">
                            <div className="textwidget">
                                <p className="headerCSS_0">
                                    <a href="https://wp-homolog.camim.com.br">
                                        <img src="https://s3-sa-east-1.amazonaws.com/camim-site/wp-content/uploads/2019/04/22115326/logo-camim.png" alt="" />
                                    </a>
                                </p>
                                <div className="fusion-sep-clear" />
                                <div className="fusion-separator fusion-full-width-sep sep-none headerCSS_1" />
                                <div className="headerCSS_2">
                                    <p className="headerCSS_3">
                                        Somos uma conceituada operadora de planos de saúde há 43 anos no mercado. Prezamos pela excelência nos atendimentos realizados em nossos postos conveniados que
                                        possuem instalações modernas, laboratórios próprios e profissionais qualificados.
                                    </p>
                                </div>
                                <div className="fusion-sep-clear" />
                                <div className="fusion-separator fusion-full-width-sep sep-none headerCSS_4" />
                                <div className="fusion-button-wrapper">
                                    <a className="fusion-button button-flat fusion-button-pill button-large button-custom button-3" target="_self" href="https://wp-homolog.camim.com.br/a-empresa/">
                                        <span className="fusion-button-text">Saiba Mais</span>
                                    </a>
                                </div>
                            </div>
                            <div className="headerCSS_5" />
                        </section>
                    </div>
                    <div className="fusion-column col-lg-4 col-md-4 col-sm-4">
                        <section id="nav_menu-2" className="fusion-footer-widget-column widget widget_nav_menu">
                            <h4 className="widget-title">Menu</h4>
                            <div className="menu-menu-principal-container">
                                <LinkMenu href="https://wp-homolog.camim.com.br/a-empresa/">A Camim</LinkMenu>
                                <LinkMenu href="https://wp-homolog.camim.com.br/especialidades/">Especialidades</LinkMenu>
                                <LinkMenu href="https://wp-homolog.camim.com.br/postos/">
                                    Postos de atendimento
                                </LinkMenu>
                                <LinkMenu href="https://wp-homolog.camim.com.br/blog/">Blog</LinkMenu>
                                <LinkMenu href="https://wp-homolog.camim.com.br/trabalhe-conosco/">Trabalhe Conosco</LinkMenu>
                                <LinkMenu href="https://wp-homolog.camim.com.br/contato/">Contato</LinkMenu>
                                <LinkMenu href="http://clubecamim.com.br/">Clube Camim</LinkMenu>
                                <LinkMenu href="https://wp-homolog.camim.com.br/quero-me-associar/">Quero me associar</LinkMenu>
                            </div>
                            <div className="headerCSS_6" />
                        </section>
                    </div>
                    <div className="fusion-column fusion-column-last col-lg-4 col-md-4 col-sm-4">
                        <section id="social_links-widget-3" className="fusion-footer-widget-column widget social_links">
                            <h4 className="widget-title">Redes Sociais</h4>
                            <div className="fusion-social-networks boxed-icons">
                                <div className="fusion-social-networks-wrapper">
                                    <SocialIcon className='iconSocialNetwork' fgColor='#fff' bgColor='#157A34' url='https://www.facebook.com/grupocamim' />
                                    <SocialIcon className='iconSocialNetwork' fgColor='#fff' bgColor='#157A34' url='https://www.youtube.com/channel/UCophxv3heBpQshsKrh7ddMQ'  />
                                    <SocialIcon className='iconSocialNetwork' fgColor='#fff' bgColor='#157A34' url='https://www.instagram.com/grupocamim/'  />
                                    <SocialIcon network='whatsapp' className='iconSocialNetwork' fgColor='#fff' bgColor='#157A34' url='http://bit.ly/WhatsAppCamim' />
                                </div>
                            </div>
                            <div className="headerCSS_11" />
                        </section>
                        <section id="media_image-2" className="fusion-footer-widget-column widget widget_media_image">
                            <a href="http://www.ans.gov.br/">
                                <img
                                    width="185"
                                    height="144"
                                    src="https://www.camim.com.br/wp-content/uploads/2019/04/ans-badge.png"
                                    className="image wp-image-1679  attachment-full size-full headerCSS_12"
                                    alt=""
                                />
                            </a>
                            <div className="headerCSS_13" />
                        </section>
                        <section id="custom_html-2" className="widget_text fusion-footer-widget-column widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <div className="headerCSS_14">
                                    <img src="https://camim.posittiva.com.br/wp-content/uploads/2019/04/WhatsApp-Image-2019-01-18-at-17.11.57.jpeg" alt="imagem" />
                                </div>
                            </div>
                            <div className="headerCSS_15" />
                        </section>
                    </div>
                    <div className="fusion-clearfix" />
                </div>
            </div>
        </footer>
        <footer id="footer" className="fusion-footer-copyright-area fusion-footer-copyright-center">
            <div className="fusion-row">
                <div className="fusion-copyright-content">
                    <div className="fusion-copyright-notice">
                        <div>
                            <p className="headerCSS_16">
                                COPYRIGHT ©2018. TODOS OS DIREITOS RESERVADOS. DESENVOLVIDO POR
                                <a className="headerCSS_17" href="https://posittiva.com.br">
                                    <b>POSITTIVA</b>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="fusion-social-links-footer" />
                </div>
            </div>
        </footer>
    </div>
)
*/

export default Footer

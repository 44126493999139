import React, { useMemo } from 'react'
import useSWR from 'swr'
import { Page, Title } from '../../components'
import { withCamim } from '../../utils'
import { checkPayment } from '../../services/FinanceService'

const WaitingPayment = ({
    translate,
    match: {
        params: { id },
    },
    history,
}) => {
    const trb = useMemo(() => {
        if (id) {
            return id
        }

        const text = (history?.location?.search || '').substring(1, 1000).trim()

        const parts = text.split('&')

        const params = parts.reduce((acc, part) => {
            const [key, value] = part.split('=')
            return {
                ...acc,
                [key]: value,
            }
        }, {})

        return params.referenceId || '0'
    }, [history?.location?.search, id])

    const { data, isValidating } = useSWR(['check-payment', trb], () => checkPayment(trb.replace(/[^0-9]/g, '')))

    const { title, messages } = useMemo(() => {
        const defaultResponses = {
            paid: 'Seu pagamento foi realizado com sucesso!',
            refunded: 'Este pagamento foi reembolsado.',
            denied: 'Pagamento não aprovado.|Por favor, entre em contato com a administradora do seu cartão para verificações.',
            waitingPayment: 'Recebemos sua solicitação de pagamento|Em breve estaremos processando seu pagamento.',
            other: 'Recebemos sua solicitação de pagamento|Em breve estaremos processando seu pagamento.',
        }
        
        const defaultTitles = {
            paid: 'Pagamento aprovado',
            denied: 'Pagamento não aprovado',
            other: 'Pagamento'
        }

        const status = data?.status || 'other'

        let message = translate(`check_payment_message_${status}`, defaultResponses[status])

        const defaultTitle = defaultTitles[status] || defaultTitles.other

        return {
            title: translate(`check_payment_title_${status}`, defaultTitle),
            messages: message.split('|')
        }
    }, [data?.status, translate])

    return (
        <Page title={title} hideBreadcrumb loading={isValidating}>
            {messages.map(x => (
                <Title key={x}>{x}</Title>
            ))}

            <Title>Muito obrigado,</Title>

            <Title>Camim</Title>
        </Page>
    )
}

export default withCamim(WaitingPayment)

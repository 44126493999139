// import fb from '../../services/CamimFirebase'

class DashboardService {
    constructor(props) {
        this.props = props 
    }  


}

export default DashboardService

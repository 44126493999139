import { connect } from 'react-redux'

const Action = {
    setForm: 'setForm',
    setValue: 'setValue',
}

const initState = {}

export const FormReducer = (state = initState, action) => {
    switch (action.type) {
        case Action.setValue:
            const { form, field, value } = action.payload
            const currentForm = {...(state[form] || {}), [field]: value }
            return {...state, [form]: currentForm }
        case Action.setForm:
            const formFill = action.payload.form
            const formValues = action.payload.values
            return {...state, [formFill]: formValues }
        case Action.clearForm:
            const formName = action.payload.form
            return {...state, [formName]: {} }
        default:
            return state
    }
}

const formField = formKey => {
    if (!formKey) {
        return null
    }

    const keys = formKey.split('.')
    if (keys.length !== 2) {
        return null
    }

    const [ form, field ] = keys

    return {
        form,
        field
    }
} 

export const formMapStateToProps = state => {

    const formState = state.form || {}

    return {
        ...state,
        getFormValue: (formKey, defaultValue = null) => {
            const f = formField(formKey)
            return (!!f && formState[f.form] && formState[f.form][f.field]) || defaultValue
        },
        getValue: (form, field) => formState[form] && formState[form][field],
        getForm: formName => formState[formName] || {},
        getFormValues: formName => formState[formName],
    }
}

export const formMapDispatchToProps = dispatch => ({
    dispatch: dispatch,
    clearForm: form => dispatch({ type: Action.setForm, payload: { form: form, values: null } }),
    setForm: (form, values) => dispatch({ type: Action.setForm, payload: { form: form, values: values } }),
    setFormValue: (formKey, value) => {
        const f = formField(formKey)
        !!f && dispatch({ type: Action.setValue, payload: { form: f.form, field: f.field, value: value } })
    },
})

export const FormConnectRedux = (mapStateToProps, mapDispatchToProps) => {

    const mstp = state => ({
        ...formMapStateToProps(state),
        ...(mapStateToProps ? mapStateToProps(state) : {})
    })

    const mdtp = dispatch => ({
        ...formMapDispatchToProps(dispatch),
        ...(mapDispatchToProps ? mapDispatchToProps(dispatch) : dispatch)
    })

    return connect(mstp, mdtp)
}
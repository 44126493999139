import React from 'react'
import { Grid, Chip } from '@material-ui/core'
import { Page, Table } from '../../components' 
import AppointmentService from '../../services/AppointmentService' 
import { withCamimSpecialties } from '../../utils'
import Week from '../../constants/Week'

const Desktop = ({ table }) => (
    <Table
        headers={[
            { title: 'Posto Conveniado', field: 'filial' },
            { title: 'Domingo', field: 'sunday' },
            { title: 'Segunda', field: 'monday' },
            { title: 'Terça', field: 'tuesday' },
            { title: 'Quarta', field: 'wednesday' },
            { title: 'Quinta', field: 'thursday' },
            { title: 'Sexta', field: 'friday' },
            { title: 'Sábado', field: 'saturday' },
        ]}
        rows={table}
    />
)

const style = {
    marginTop: 16, 
    marginBottom: 8,
    textAlign: 'center',
}

const itemType = {
    color: '#fff',
    backgroundColor: '#607d8b',
    fontSize: 14,
    fontWeight: 500,
}

const bordeBottomStyle = {
    borderBottomColor: '#607d8b',
    borderBottomWidth: 0.6,
    borderBottomStyle: 'dotted',
    paddingTop: 10,
}

const Mobile = ({ table }) => {

    const getWeek = slug => (Week.find(e => e.slug === slug) || {}).title

    const items = table.map(({ filial, specialty, ...week }) => ({ filial, times: Object.keys(week).map(day => ({ day, week: week[day] })) }))

    return items.map((item, index) => (
        <div key={index} style={{ marginBottom: '3em' }}>
            <div style={style}>
                <Chip label={item.filial} variant='primary' style={itemType} />
            </div>
            {item.times.map((time, indexTime) => (
                <Grid container spacing={3} key={indexTime} style={bordeBottomStyle}>
                    <Grid item xs={8}>
                        {getWeek(time.day)}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        {time.week}
                    </Grid>
                </Grid>
            ))}
        </div>
    ))
}

class TimeAttendanceAvailable extends React.Component {
    state = { }

    get service() {
        if (!this._service) {
            this._service = new AppointmentService(this.props)
        }
        return this._service
    }

    request = async () => {
        try {
            const { match: { params: { hash }}} = this.props
            this.setState({ isRequesting: true })
            this.setState({ table: await this.service.attendanceTime(hash), isRequesting: false })
        } catch (error) {
            console.log('error', error)
        }
    }

    componentDidMount = () => this.request()
    
    render = () => {
        const { specialties = [], isMobile, match: { params: { hash }}} = this.props
        const { table = [] } = this.state
        const specialty = specialties.find(e => e.hash === hash)
        let title = (!specialty ? (hash || 'Horários de Disponíveis') : specialty.title).replace(/_/g, ' ')
        if (title === 'MAMOGRAFIA-DENSITOMETRIA') {
            title = 'MAMOGRAFIA/DENSITOMETRIA'
        }
        
        return (
            <Page 
                title={title}
                card 
                breads={[
                    { title: 'Horários de Atendimento', href: 'https://www.camim.com.br/especialidades' }
                ]}
            >
                {!isMobile && <Desktop table={table} />}
                {isMobile && <Mobile table={table} />}
            </Page>
        )
    }
}

export default withCamimSpecialties(TimeAttendanceAvailable)

    
// import Realtime from './Firebase/Realtime'
import Firestore from './Firebase/Firestore'
import moment from 'moment'
import Config from '~/constants/Config'
import { Observable, Subject } from 'rx'
import Mask from '~/constants/Mask'
import ActionResult from '~/models/ActionResult'

export class CamimFirebase extends Firestore {

    _currentTimeout = undefined

    setUser = user => this.user = user

    setNewTimeout = time => {
        this._currentTimeout = time
        return this
    }

    /**
     * Firebase dados Camim
     */
    isForceRequest = false
    responseSubscription = null
    loading = new Subject()

    requestFirebase = (action, filial, parameters, callback) => {
        const paramsX = parameters || {}
        paramsX.action = action

        const { examUser, ...params } = paramsX 

        const user = {...(this.user || examUser || { idCliente: 0, code: filial.code })}

        if (user.idCliente > 0 && !this.isForceRequest) {

            const data = user.dependente || user

            if (data.age) {
                params.age = data.age
            } else if (data.dataNascimento || data.dataNascimentoDependente) {
                const dob = moment(data.dataNascimento, Mask.SQL_FULL_DATETIME_MASK)
                params.age = moment().diff(dob, 'years')
            }

            params.idCliente = user.idCliente
            params.idDependente = user.idDependente
            params.matricula = user.matricula
            params.nome = data.nome
            params.name = data.nome
            params.filialMatricula = user.codeFilial
            params.codigoPessoal = data.codigoPessoal
            if (!params.codigoPessoal) {
                delete params.codigoPessoal
            }
        }

        const mainNode = `${this.mainNode || 'production'}/${filial.node}`

        const isLogin = action === 'login' && !this.isForceRequest

        const requestKey = !isLogin ? 'request' : 'login'
        const responseKey = !isLogin ? 'response' : 'loginResponse'
        this.isForceRequest = false

        const hashDate = moment().format(Mask.FIREBASE_KEY_DATETIME)
        const hash = `${action}${hashDate}_${user.code || user.codeFilial}_${user?.idCliente || 0}`

        const requestNode = `${mainNode}/${requestKey}/${hash}`
        const responseNode = `${mainNode}/${responseKey}/${hash}`

        params.intDate = parseInt(hashDate, 10)

        if (!Config.isProduction) {
            // Debugger
            console.log('>>>')
            console.log('>>>------------------------------------------------------------------------')
            console.log('>>> Request', requestNode)
            console.log('>>> Data', JSON.stringify(params))
            console.log('>>> Response', responseNode)
            console.log('>>>------------------------------------------------------------------------')
            console.log('>>>')
        }

        // Adiciona o observer

        try {
            const observer = this.on(responseNode)

            this.loading.onNext(true)

            this.responseSubscription = observer.subscribe(
                value => {
                    if (!!value) {
                        !Config.isProduction && console.log(`Response Action: ${action}`, responseNode, value)
                        this.clearSubscription(requestNode, responseNode)
                        this.manageCallback(callback)(new ActionResult(value))
                    }
                },
                error => {
                    this.clearSubscription(requestNode, responseNode)
                    this.manageCallback(callback)(new ActionResult({ success: false, filialCode: filial.code, message: error.error }))
                }
            )

            // Seta o valor
            this.set(requestNode, params)

            this.timeoutSubscriber = setTimeout(() => {
                if (!this.timeoutSubscriber) {
                    return
                }
                this.clearSubscription(requestNode, responseNode)
                !Config.isProduction && console.log('timeout', Config.timeToExpire * 1000)
                const jsonData = { success: false, filialCode: filial.code, message: 'Tempo esgotado de solicitação' }
                console.log('>>> timeout', jsonData)
                this.manageCallback(callback)(new ActionResult(jsonData))
            }, (this._currentTimeout || Config.timeToExpire) * 1000)
        } catch (err) {
            this.clearSubscription(requestNode, responseNode)
            console.log('error', err)
            console.log('error', JSON.stringify(params))
            this.manageCallback(callback)(new ActionResult({ success: false, filialCode: filial.code, message: 'Erro inespecífico', params }))
        }
    }

    manageCallback = callback => value => {
        !Config.isProduction && console.log('>>> response value to callback', value)
        this._currentTimeout = undefined
        callback(value)
    }

    mergeRequestObservable = (filials, action, parameters) => Observable.merge(...filials.map(e => this.requestObservable(action, e, parameters)))

    requestObservable = (action, filial, parameters, bypassError = false) =>
        Observable.create(emitter =>
            this.requestFirebase(action, filial, parameters, result => {
                !Config.isProduction && console.log('req fb', result)
                if (result.success || bypassError) {
                    emitter.onNext(result)
                }
                else {
                    emitter.onError(result)
                }
                emitter.onCompleted()
            })
        )

    request = (action, filial, parameters, bypassError = false) => new Promise((then, reject) => {
        this.requestFirebase(action, filial, parameters, result => {
            !Config.isProduction && console.log('req fb', result)
            if (result.success || bypassError) {
                then(result)
            }
            else {
                reject(result)
            }
        })
    })

    clearSubscription = (requestNode, responseNode) => {
        this.loading.onNext(false)

        !!this.timeoutSubscriber && clearTimeout(this.timeoutSubscriber)
        this.responseSubscription && this.responseSubscription.dispose()

        this.responseSubscription = null
        this.timeoutSubscriber = null

        this.delete(requestNode)
        this.delete(responseNode)
    }

    config = node => this.once('config/' + node)

    /**
     * Filials
     */

    // filialRepository = new FilialRepository([Filial])
    filials = []

    requestFilials = () => this.config('filiais').map(dict => Object.values(dict))
}

export default new CamimFirebase()

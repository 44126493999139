import React from 'react'
import { Grid } from '@material-ui/core'
import { Page, Button, Input, Title, Card } from '../../components'
import { utils } from '../../utils'
import { Link } from 'react-router-dom'
import LoginService from '../../services/LoginService'
import '../../assets/styles/css/login.css'

class Login extends React.PureComponent {
    state = {}

    constructor(props) {
        super(props)
        props.setForm('login', {})
    }

    get loginService() {
        if (!this._loginService) {
            this._loginService = new LoginService(this.props)
        }
        return this._loginService
    }

    canLogin = () => {
        if (!this.props.filials) {
            return false
        }

        const { matricula = '', password = '' } = this.props.getForm('login')
        if (matricula.trim() === '' || password.trim().length !== 6) {
            return false
        }

        const pswNumbers = password.replace(/[^0-9]/g, '')
        if (pswNumbers.length !== 6) {
            return false
        }

        const letter = matricula.replace(/[0-9]/g, '')
        if (letter.length !== 1) {
            return false
        }

        const filials = this.props.filials.map(e => e.code.toUpperCase())
        if (!filials.includes(letter.toUpperCase())) {
            return false
        }

        const psd = `${matricula.replace(/[^0-9]/g, '')}${letter}`
        if (psd !== matricula) {
            return false
        }
        return true
    }

    login = async evt => {
        evt.preventDefault()
        const { getForm } = this.props
        const { matricula, password } = getForm('login')

        const code = matricula.replace(/[0-9]/g, '').toUpperCase()
        const matNumber = matricula.replace(/[^0-9]/g, '')

        const filial = this.props.filials.find(e => e.code === code)

        this.setState({ isRequesting: true, error: null })
        try {
            await this.loginService.login(matNumber, password, filial)
            this.setState({ isRequesting: false })
        } catch (error) {
            this.setState({ isRequesting: false, error })
            setTimeout(() => this.setState({ error: null }), 5000)
        }
    }

    render = () => {
        const { isRequesting = false, error } = this.state
        const { filials, hasPortabilidade = false, isMobile, isRegularScreen } = this.props
        return (
            <Page title='Login' hideBreadcrumb error={error} onDismissError={() => this.setState({ error: null })}>
                <Grid container>
                    {!isMobile && <Grid item xs={isRegularScreen ? 2 : 3} />}
                    <Grid item xs={isMobile ? 12 : isRegularScreen ? 8 : 6}>
                        {hasPortabilidade && (
                            <div className='ansTitle'>
                                ANS concede portabilidade especial aos beneficiários da Camim.
                            </div>
                        )}
                        <Card>
                            {hasPortabilidade && (
                                <div className='ansSubtitle'>
                                    Saiba mais logando na área restrita para associados.
                                </div>
                            )}
                            <Title success underline>Entrar</Title>

                            <form className="login-form" autoComplete="off" onSubmit={this.login}>
                                <Input 
                                    title="Matrícula" 
                                    placeholder="Informe a matrícula" 
                                    field="login.matricula" 
                                    style={{ marginBottom: 12 }} 
                                    onFormatValue={utils.allowMatricula(filials)}
                                />

                                <Input 
                                    title="Senha" 
                                    placeholder="Informe a senha com 6 dígitos" 
                                    type="password" 
                                    maxLength="6" 
                                    field="login.password" 
                                    onFormatValue={utils.limitPassword}
                                />
                                <br />
                                <br />
                                <Grid container>
                                    <Grid item xs={isMobile ? 7 : 6}>
                                        <Link to='/recuperar-senha'>
                                            <Button type="button" variant="text" disabled={isRequesting} className="sectionColor btn-forgot">
                                                Esqueci a senha
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={isMobile ? 5 : 6} style={{ textAlign: 'right' }}>
                                        <Button type="submit" tabIndex="-1" isRequesting={isRequesting} disabled={isRequesting || !this.canLogin()} color={this.canLogin() ? 'primary' : 'secondary'}>
                                            Entrar
                                        </Button>
                                    </Grid>
                                </Grid>
                                <br />
                                <div className="destaque left">
                                    O último caracter da matrícula deve obrigatoriamente conter a letra correspondente, como consta na Carteirinha e no Contrato.
                                </div>
                            </form>
                        </Card>
                    </Grid>
                </Grid>

            </Page>
        )
    }
}

export default Login

import FirebaseService from './FirebaseService'
import { Observable } from 'rx'

class ExamService extends FirebaseService {

    exams = callback => {
        this.nextFilial(null, callback)
        return Observable.concat(...this.filials.map(e => this.firebase.requestObservable('testResults', e, !this.user && !!this.props.userExam && { examUser: this.props.userExam }, true))).map(({ data: { filialCode }, items }) => {
            this.nextFilial(filialCode, callback)
            return items
        })
    }

}

export default ExamService

import React  from 'react'
import Container from './modules/Container/Container'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { loadReCaptcha } from 'react-recaptcha-google'
import './assets/styles/css/app.css'
import './assets/styles/css/style.css'

loadReCaptcha()

export default () => (
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <Container />
        </PersistGate>
    </Provider>
)
